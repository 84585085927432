import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Layout, Space, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import trayaLogo from "../../../assets/traya.png";
import { getUserInfo } from "../../engagementfeedback/service";

const ThankYouPageV1 = () => {
  let history = useHistory();
  let { id } = useParams();
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [cartLink, setCartLink] = useState("");
  const [newCartLink, setNewCartLink] = useState("");
  const [userAnalysis, setUserAnalysis] = useState({});
  const [recommendation, setRecommendation] = useState({});

  const userDetails = async (id) => {
    const _res = await getUserInfo(id);
    setData(_res.order);
    setUserAnalysis(_res.User_Analysis);
    setRecommendation(_res.Recommendations);
  };
  useEffect(() => {
    userDetails(id);
    let isHairLoss = window.localStorage.getItem("hair_loss");
    let isDandruff = window.localStorage.getItem("dandruff_feedback");
    let nextCartLink = window.localStorage.getItem("next_cart_link");
    setCartLink(nextCartLink);
    let nextNewCartLink = window.localStorage.getItem("next_cart_link_3_months")
    setNewCartLink(nextNewCartLink)
    if (isHairLoss == 1 && isDandruff == 1) {
      setVisible(true);
    }
  }, [id]);

  let _date = window.localStorage.getItem("date");
  let _startTime = window.localStorage.getItem("startTime");

  const goBack = () => {
    history.push(`/${id}`);
  };

  const keys = Object.keys(userAnalysis);
  let rootCause = "";
  let rootCauseArr = [];
  keys.map((key) => {
    switch (key) {
      case "sleep":
        rootCause = "Sleep";
        break;
      case "stress":
        rootCause = "Stress";
        break;
      case "gut_health":
        rootCause = "Gut Health";
        break;
      case "energy":
        rootCause = "Energy";
        break;
      default:
        return;
    }
    rootCauseArr.push(rootCause);
  });

  return (
    <Layout style={{ backgroundColor: "White" }}>
      <div>
        <Row
          align="middle"
          justify="center"
          style={{ width: "100%", position: "fixed", overflowY: "hidden" }}
        >
          <div style={{ width: "100%", backgroundColor: "rgb(65,64,66)" }}>
            <img
              src={trayaLogo}
              alt="Logo"
              style={{
                marginTop: "10px",
                marginLeft: "17px",
                padding: "17px",
                cursor: "pointer",
              }}
              onClick={goBack}
              width={130}
            />
          </div>
        </Row>
       
        <Row align="middle" justify="center">
          <div className="cardView container-4">
            <Space direction="vertical" style={{ width: "100%" }}>
              <div style={{ width: "100%", height: "auto" }}>
                <div style={{ marginTop: "10px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#414043",
                      opacity: "0.8",
                    }}
                  >
                    Thank you for your feedback.
                  </span>
                </div>
                <div className="bar"></div>
                <div style={{ marginTop: "10px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "116.9%",
                      color: "#414143",
                    }}
                  >
                    We have booked an appointment with your coach who will
                    discuss and plan the next steps. Remember, we are here for you
                    to help you in every way possible.
                  </span>
                </div>
                <div className="bar"></div>

                <div className="card-1">
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#414043",
                    }}
                  >
                    Appointment: <strong>Booked</strong>
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#414043",
                    }}
                  >
                    Time: <strong>{_startTime}</strong>
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#414043",
                    }}
                  >
                    Date: <strong>{_date}</strong>
                  </span>
                </div>

                {visible && (
                  <>
                    <div style={{ marginTop: "10px",marginBottom:"10px"  }}>
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontSize: "20px",
                          fontWeight: "700",
                          lineHeight: "118%",
                          color: "#414143",
                          opacity: "0.8",
                        }}
                      >
                        According to your feedback, below is the next suggested
                        month plan.
                      </span>
                    </div>
                    <Button
                      className="finalBtn"
                      href={cartLink}
                      type="link"
                    >
                      <span style={{ marginTop: "8px" }}>
                        {" "}
                        ORDER MY NEXT MONTH KIT
                      </span>
                    </Button>
                    <Button
                      className="finalBtn"
                      href={newCartLink}
                      type="link"
                    >
                      <span style={{ marginTop: "8px" }}>
                        {" "}
                        ORDER MY NEXT 3 MONTH KIT
                      </span>
                    </Button>
                  </>
                )}
              </div>
            </Space>
          </div>
        </Row>
      </div>
    </Layout>
  );
};

export default ThankYouPageV1;



// <Row align="middle" justify="center">
// <div
//   className="cardView container-3"
//   style={{ border: "1px solid #B7D340", borderRadius: "9px" }}
// >
//   <div className="userCard">
//     <div className="userContainer">
//       <div
//         style={{
//           width: "250px",
//           height: "29px",
//           marginTop: "-8px",
//           textAlign: "left",
//         }}
//       >
//         <span
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             fontStyle: "normal",
//             fontWeight: "700",
//             fontSize: "18px",
//             lineHeight: "22px",
//             color: "#414042",
//           }}
//         >
//           {userAnalysis.first_name}
//         </span>
//         {userAnalysis.age && <span
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             fontStyle: "italic",
//             fontWeight: "400",
//             fontSize: "18px",
//             lineHeight: "22px",
//             color: "#414042",
//           }}
//         >
//           {", "}
//           {userAnalysis.age}
//           {/* {userAnalysis.gender === "M" ? "Male" : "Female"} */}
//         </span>}
//         {userAnalysis.gender && <span
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             fontStyle: "italic",
//             fontWeight: "400",
//             fontSize: "18px",
//             lineHeight: "22px",
//             color: "#414042",
//           }}
//         >
//           {", "}
//           {userAnalysis.gender === "M" ? "Male" : "Female"}
//         </span>}
//       </div>

//       <div
//         style={{
//           width: "90px",
//           height: "55px",
//           marginTop: "10px",
//           textAlign: "right",
//         }}
//       >
//         <div
//           style={{
//             width: "90px",
//             height: "25px",
//             marginTop: "-18px",
//           }}
//         >
//           <span
//             style={{
//               fontFamily: "Nunito Sans, sans-serif",
//               fontStyle: "normal",
//               fontWeight: "500",
//               fontSize: "14px",
//               lineHeight: "19px",
//               color: "#000000",
//             }}
//           >
//             {moment(data.delivery_date).format("DD MMM YYYY")}
//           </span>
//         </div>
//         <div
//           style={{
//             width: "90px",
//             height: "25px",
//             marginTop: "-6px",
//             marginLeft: "7px",
//           }}
//         >
//           {data.order_display_id && <span
//             style={{
//               fontFamily: "Nunito Sans, sans-serif",
//               fontStyle: "normal",
//               fontWeight: "400",
//               fontSize: "9px",
//               lineHeight: "12px",
//               color: "#414042",
//             }}
//           >
//             Order ID: {data.order_display_id}
//           </span>}
//         </div>
//       </div>
//     </div>

//     <div className="rootCauseContainer">
//       <div
//         style={{
//           display: "flex",
//         }}
//       >
//         <div
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             fontStyle: "normal",
//             fontWeight: "400",
//             width: "110px",
//             height: "30px",
//             color: "#414042",
//             opacity: "0.7",
//             marginLeft: "-6px",
//             marginTop: "-4px",
//             textAlign: "left",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "11px",
//               lineHeight: "17px",
//             }}
//           >
//             Current Diagnosis:
//           </span>
//         </div>
//         <div
//           className='diagnosisBarFinal-1'
//         ></div>
//         <div
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             width: "300px",
//             height: "21px",
//             color: "#000000",
//             marginTop: "-4px",
//             marginLeft: "20px",
//             textAlign: "left",
//           }}
//         >
//           {userAnalysis.gender === "M" ? (
//             <>
//             {recommendation.Type_of_hair_loss && <span
//               style={{
//                 fontWeight: "600",
//                 fontStyle: "normal",
//                 fontSize: "13px",
//                 lineHeight: "19px",
//               }}
//             >
//               {" "}
//               {recommendation.Type_of_hair_loss}
//             </span>}
//             {userAnalysis.stage && <span
//               style={{
//                 fontWeight: "600",
//                 fontStyle: "normal",
//                 fontSize: "13px",
//                 lineHeight: "19px",
//               }}
//             >
//               {", "}
//               {userAnalysis.stage}
//             </span>}
//             </>
//           ) : (
//             <>
//             <span
//               style={{
//                 fontWeight: "600",
//                 fontStyle: "normal",
//                 fontSize: "13px",
//                 lineHeight: "19px",
//               }}
//             >
//               Female pattern hair loss
//             </span>
//             {userAnalysis.stages && <span
//               style={{
//                 fontWeight: "600",
//                 fontStyle: "normal",
//                 fontSize: "13px",
//                 lineHeight: "19px",
//               }}
//             >
//               {","}{userAnalysis.stages}
//             </span>}
//             </>
//           )}
//         </div>
//       </div>
//       <div className="userCardbar"></div>
//       <div
//         style={{
//           display: "flex",
//         }}
//       >
//         <div
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             fontStyle: "normal",
//             fontWeight: "400",
//             width: "110px",
//             height: "30px",
//             color: "#414042",
//             opacity: "0.7",
//             marginLeft: "-8px",
//             marginTop: "6px",
//             textAlign: "left",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "11px",
//               lineHeight: "17px",
//             }}
//           >
//             Root causes affecting hair loss:
//           </span>
//         </div>
//         <div
//           className="diagnosisBarFinal-2"
//         ></div>
//         <div
//           style={{
//             fontFamily: "Nunito Sans, sans-serif",
//             width: "300px",
//             height: "21px",
//             color: "#000000",
//             marginTop: "16px",
//             marginLeft: "17px",
//             textAlign: "left",
//           }}
//         >
//           {rootCauseArr.map((root, i) => {
//             return (
//               <span
//                 key={i}
//                 style={{
//                   fontWeight: "600",
//                   fontStyle: "normal",
//                   fontSize: "13px",
//                   lineHeight: "19px",
//                 }}
//               >
//                 {i > 0 && ", "}

//                 {root}
//               </span>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </Row>

