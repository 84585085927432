import { SECURITY_TOKEN } from "../helpers/api-config";

export const fetchRequest = async (url, options = { method: "GET" }) => {
  let data = {};
  let status = "";

  try {
    const _options = {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SECURITY_TOKEN}`,
      },
    };

    const _res = await fetch(url, _options);
    status = _res.status;

    const contentType = _res.headers.get("content-type");
    if (contentType?.includes("application/json")) data = await _res.json();
  } catch (error) {
    console.warn(error.message);
  } finally {
    return { data, hasError: !(status === 200), status };
  }
};
