import React, { useCallback, useContext, useEffect, useState } from "react";

import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

import { GET_SLOTS_API } from "../constants/urls";
import { fetchRequest } from "../helpers/fetchRequest";
import { groupSlots } from "./handler";

import DateList from "../components/DateList";
import SlotsList from "../components/SlotsList";
import "./Calendar.css";

export const SlotBooking = ({
  caseId,
  title,
  api,
  handleBooking,
  selectedInfo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [slots, setSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [hasError, sethasError] = useState(false);
  const [hasBooked, setHasBooked] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedSlots, setSelectedSlots] = useState({});

  const slotAPIs = {
    SLOTS_API: GET_SLOTS_API,
  };

  const updateSlots = useCallback(async () => {
    setIsLoading(true);
    const getSlots = slotAPIs[api];

    const _res = await fetchRequest(getSlots(caseId));

    setIsLoading(false);
    if (_res.status === 409) {
      setHasBooked(true);
      return;
    } else if (_res.hasError) {
      sethasError(true);
      return;
    }
    if (isEmpty(_res.data)) return;

    const _data = groupSlots(_res.data);
    setSlots(_data);
    setSelectedDate(selectedSlots ? selectedSlots.date : "");
  }, [caseId, selectedSlots, api]);

  const handleSlotBooking = async (date, slotId) => {
    if (selectedInfo) {
      selectedInfo({ date: selectedDate, time: selectedSlot.slotTime });
    }
    const _res = await handleBooking(date, slotId);
    if (!Boolean(_res)) return;
    if (_res.status === 400 || _res.updateSlots) updateSlots();
  };

  useEffect(() => {
    updateSlots().then(() => {
      setTimeout(() => {
        if (window.screen.width < 600) {
          window.scrollBy({
            top: window.innerHeight - 100,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 500);
    });
  }, [caseId]);

  useEffect(() => {
    if (isEmpty(selectedSlot)) return;
    const _date = dayjs(selectedSlot.slotTime).format("YYYY-MM-DD");
    handleSlotBooking(_date, selectedSlot.id);
  }, [selectedSlot]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          padding: "1rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <p
          style={{
            height: "auto",
            fontFamily: "Nunito Sans",
          }}
        >
          Checking availability...
        </p>
      </div>
    );
  }
  if (hasError) {
    return (
      <div
        style={{
          display: "flex",
          padding: "1rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            height: "auto",
            fontFamily: "Nunito Sans",
          }}
        >
          This link is invalid, please contact your hair coach for more
          information.
        </p>
      </div>
    );
  }

  if (isEmpty(slots) && !hasBooked) {
    return (
      <div
        style={{
          display: "flex",
          padding: "1rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            height: "auto",
            fontFamily: "Nunito Sans",
          }}
        >
          No slots
        </p>
      </div>
    );
  }

  if (hasBooked) {
    return (
      <div
        style={{
          display: "flex",
          padding: "1rem",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            height: "auto",
            fontFamily: "Nunito Sans",
          }}
        >
          You have already booked a slot. Your doctor will be in touch with you
          shortly
        </p>
      </div>
    );
  }

  return (
    <>
      <h2
        style={{
          marginBottom: "0.25rem",
          marginTop: "1rem",
          fontSize: "1.25rem",
          lineHeight: "1.75rem",
          fontWeight: "700",
          textAlign: "center",
          color: "#414042",
          fontFamily: "Nunito Sans",
        }}
      >
        {title}
      </h2>

      <div
        style={{
          display: "flex",
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          gap: "0.25rem",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "auto" }}>
          <div
            style={{
              display: "flex",
              overflowX: "hidden",
              paddingBottom: "0.5rem",
              width: "100%",
              borderStyle: "none",
              gap: "0.25rem",
            }}
          >
            <div
              style={{
                marginTop: "2rem",
                width: " 100%",
                height: "100%",
              }}
            >
              <DateList
                slots={slots}
                selectedDate={selectedDate}
                setDate={setSelectedDate}
                setSlots={setSlots}
              />

              <SlotsList
                selectedDate={selectedDate}
                slots={slots}
                selectedSlot={selectedSlot}
                setSlot={setSelectedSlot}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
