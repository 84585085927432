import React, { useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import { getDates } from "./helpers";

dayjs.extend(isBetween);

const DateList = ({ slots, selectedDate, setDate, setSlots }) => {
  const _dateList = useMemo(() => {
    const _data = getDates(slots);
    return isEmpty(_data) ? [] : _data;
  }, [slots]);

  useEffect(() => {
    if (isEmpty(_dateList)) setSlots({});
    setDate(_dateList[0]?.date);
  }, [_dateList, setDate, setSlots]);

  const isSlotsAvailabe = (date) => {
    const _date = dayjs(date).format("YYYY-MM-DD");
    const _slots = slots[_date];

    return _slots ? true : false;
  };

  return (
    <div
      style={{
        width: "max-content",
        maxWidth: "100%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          paddingBottom: "0.5rem",
          marginTop: "1rem",
          width: "100%",
          gap: "0.5rem",
          fontFamily: "Nunito Sans",
        }}
      >
        {_dateList.map(({ date, day }, index) => {
          const _isSelectedDate = date.isSame(selectedDate, "day");
          const _isSlotsAvailabe = isSlotsAvailabe(date);

          return (
            <button
              type="button"
              disabled={!_isSlotsAvailabe}
              onClick={() => setDate(date)}
              key={index}
              className={`ab ${_isSelectedDate ? "p-color" : "x-color"}`}
            >
              <span
                style={{
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  width: "4rem",
                }}
              >
                {day}
              </span>
              <span
                style={{
                  fontSize: "1.125rem",
                  lineHeight: "1.75rem",
                }}
              >
                {date.format("D")}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DateList;
