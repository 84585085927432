import React from "react";

const Modal = ({ setModalOn, setSlot, slot }) => {
  const handleClose = () => {
    setModalOn(false);
  };

  let _date = window.localStorage.getItem("date");
  let _startTime = window.localStorage.getItem("startTime");

  return (
    <div
      style={{
        overflowY: "auto",
        position: "fixed",
        top: "0",
        right: "0",
        bottom: " 0",
        left: "0",
        backgroundColor: "rgba(138,138,138,0.05)",
        transition: "opacity 250ms ease",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignitems: "center",
        }}
      >
        <form
          style={{
            padding: "2rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            borderWidth: "1px",
            height: "38vh",
            marginTop: "14%",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            setSlot(slot);
          }}
        >
          <p
            style={{
              fontSize: "1.125rem",
              lineHeight: "1.75rem",
            }}
          >
            <strong>Please confirm your booking details below</strong>
          </p>

          <div>
            <p>
              <strong>Date :</strong> {_date}
            </p>
            <p>
              <strong>Start Time :</strong> {_startTime}
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              onClick={() => {
                setSlot(slot);
              }}
              style={{
                display: "inline-block",
                padding:"0.5rem 1rem",
                marginTop: "0.25rem",
                color: "white",
                background: "#9bba70",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                fontWeight: "600",
                letterSpacing: "0.025em",
                textAlign: "center",
                textTransform: "uppercase",
                borderRadius: "0.375rem",
                border: "none",
                cursor: "pointer",
              }}
            >
              Confirm
            </button>
            <button
              onClick={handleClose}
              style={{
                display: "inline-block",
                padding:"0.5rem 1rem",
                marginTop: "0.25rem",
                marginLeft: "0.5rem",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                fontWeight: "600",
                letterSpacing: "0.025em",
                textAlign: "center",
                textTransform: "uppercase",
                borderRadius: " 0.375rem",
                background: "white",
                cursor: "pointer",
                border: "0.5px solid lightgrey",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
