import {
  message,
  Row,
  Layout,
  Space,
  Col,
  Radio,
  Form,
  Input,
  Button,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
// Ant Design

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import trayaLogo from "../../../assets/traya.png";
import { engagementFeedback, getUserInfo } from "../service";
import ArrowCircleRight from "../../../assets/icon/ArrowCircleRight";
import { NEW_FEEDBACK_FORM, NEW_FEEDBACK_FORM_DEV } from "../../../constants/urls";
// Ends

const NewEngagementFeedback = () => {
  const [form] = Form.useForm();

  const { id } = useParams();
  console.log("id", id);
  let history = useHistory();
  const [selectedTags, setSelectedTags] = useState(["CONSISTENCY"]);
  const [showFields, setShowFields] = useState({});
  const [btnVisible, setBtnVisible] = useState(false);
  const [customerAdherence, setCustomerAdherence] = useState("");
  const [adherenceReason, setAdherenceReason] = useState("");
  const [mood, setMood] = useState(0);
  const [anyProductReaction, setAnyProductReaction] = useState("");
  const [energy, setEnergy] = useState(0);
  const [gutHealth, setGutHealth] = useState(0);
  const [hairGrowth, setHairGrowth] = useState(0);
  const [hairLoss, setHairLoss] = useState(0);
  const [comment, setComment] = useState("");
  const [productReaction, setProductReaction] = useState("");
  const [notOrderingReason, setNotOrderingReason] = useState("");
  const [isFilled, setIsFilled] = useState(true);
  const [cartLink, setCartLink] = useState("");
  const [newCartLink, setNewCartLink] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  //new keys
  const [diet, setDiet] = useState("");
  const [constipation, setConstipation] = useState(0);
  const [digestion, setDigestion] = useState(0);
  const [overallHealth, setOverallHealth] = useState(0);
  const [scalpFeedback, setScalpFeedback] = useState(0);
  const [hairFeedback, setHairFeedback] = useState("");
  const [hairBreakage, setHairBreakage] = useState("");
  const [dandruffFeedback, setDandruffFeedback] = useState(0);
  const [concerns, setConcerns] = useState("");
  //storing data
  const [userInfo, setUserInfo] = useState({});
  const [recommendation, setRecommendation] = useState({});
  const [userAnalysis, setUserAnalysis] = useState({});
  // validation parameters
  const [visible, setVisible] = useState(false);
  const [bookingBtn, setBookingBtn] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [selectedConsistencyData, setSelectedConsistencyData] = useState("");
  const [selectedOverallHealthData, setSelectedOverallHealthData] =
    useState("");
  const [selectedHairHealthData, setSelectedHairHealthData] = useState("");
  const [userData, setUserData] = useState({});
  const [data, setData] = useState({});
  const [concernVisible, setConcernVisible] = useState(false);

  // question validation

  const [isScalp, setIsScalp] = useState(false);
  const [isHairLoss, setIsHairLoss] = useState(false);
  const [isHairGrowth, setIsHairGrowth] = useState(false);
  const [isHairBreakage, setIsHairBreakage] = useState(false);
  const [isHairFeedback, setIsHairFeedback] = useState(false);
  const [isDandruffFeedback, setIsDandruffFeedback] = useState(false);
  const [isOverallHealthEmpty, setIsOverallHealthEmpty] = useState(false);
  const [isHairHealthEmpty, setIsHairHealthEmpty] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const tagsData = ["CONSISTENCY", "OVERALL HEALTH", "HAIR HEALTH"];
  let consistencyData = ["customer_adherence", "diet"];
  let overallHealthData = [
    "gut_shuddi",
    "digest_boost",
    "health_tatva",
    "nasya",
    "overall_health",
  ];
  const [overallHealthArray, setOverallHealthArray] =
    useState(overallHealthData);
  let hairHealthData = [
    "scalp_feedback_male",
    "hair_loss_male",
    "hair_feedback_male",
    "hair_growth_male",
    "dandruff_feedback_male",
    "concerns_male",
    "scalp_feedback_female",
    "hair_loss_female",
    "hair_growth_female",
    "hair_breakage",
    "hair_feedback_female",
    "dandruff_feedback_female",
    "concerns_female",
  ];
  const [hairHealthArray, setHairHealthArray] = useState(hairHealthData);

  const handleShowFields = (name, value) => {
    setShowFields((curr) => {
      const _curr = curr;
      _curr[name] = value;
      return { ..._curr };
    });
  };

  useEffect(() => {
    // handleShowFields("customer_adherence", true);
    handleUserInfo(id);
    if (id) {
      window.location.replace(`${NEW_FEEDBACK_FORM}/${id}`);
    }
    window.localStorage.removeItem("hair_loss");
    window.localStorage.removeItem("concerns");
    window.localStorage.removeItem("dandruff_feedback");
    window.localStorage.removeItem("date");
    window.localStorage.removeItem("hair_fall");
    window.localStorage.removeItem("next_cart_link");
    window.localStorage.removeItem("engagement_id");
    window.localStorage.removeItem("mood");
    window.localStorage.removeItem("case_id");
    window.localStorage.removeItem("startTime");
    window.localStorage.removeItem("metabolism");
    window.localStorage.removeItem("dandruff");
    window.localStorage.removeItem("gut_health");
  }, [id]);

  const handleUserInfo = async (id) => {
    const _res = await getUserInfo(id);
    setUserInfo(_res);
    setRecommendation(_res.Recommendations);
    setUserAnalysis(_res.User_Analysis);
    setUserData(_res.order.user);
    setData(_res.order);
  };
  let overallHealthArr = [];
  let hairHealthArr = [];

  useEffect(() => {
    if (recommendation.Shampoo === "Anti dandruff") {
      setVisible(true);
    } else if (recommendation.Anti_danruff_shampoo === "Yes") {
      setVisible(true);
    } else if (recommendation.Kevon === "Yes") {
      setVisible(true);
    }
    if (userInfo.order_count >= 1 && userInfo.order_count <= 12) {
      consistencyData.push("customer_adherence", "diet");
      setSelectedConsistencyData("customer_adherence");
    }
    if (
      recommendation.Gut_shuddi === "No" &&
      recommendation.Digest_Boost === "No" &&
      recommendation.Health_tatva === "No" &&
      recommendation.Nasya === "No" &&
      userInfo.order_count >= 1 &&
      userInfo.order_count <= 6
    ) {
      overallHealthArr.push("overall_health");
      setSelectedOverallHealthData("overall_health");
    }
    if (
      recommendation.Nasya === "Yes" &&
      userInfo.order_count >= 1 &&
      userInfo.order_count <= 6
    ) {
      overallHealthArr.push("nasya");
      setSelectedOverallHealthData("nasya");
    }
    if (
      recommendation.Health_tatva === "Yes" &&
      userInfo.order_count >= 1 &&
      userInfo.order_count <= 5
    ) {
      overallHealthArr.push("health_tatva");
      setSelectedOverallHealthData("health_tatva");
    }
    if (recommendation.Digest_Boost === "Yes") {
      overallHealthArr.push("digest_boost");
      setSelectedOverallHealthData("digest_boost");
    }
    if (
      recommendation.Gut_shuddi === "Yes" &&
      userInfo.order_count >= 1 &&
      userInfo.order_count <= 5
    ) {
      overallHealthArr.push("gut_shuddi");
      setSelectedOverallHealthData("gut_shuddi");
    }
    overallHealthArr.reverse();

    if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
      if (
        concernVisible &&
        userInfo.order_count >= 1 &&
        userInfo.order_count <= 8
      ) {
        hairHealthArr.push("concerns_male");
        setSelectedHairHealthData("concerns_male");
      }
      if (visible && userInfo.order_count >= 1 && userInfo.order_count <= 2) {
        hairHealthArr.push("dandruff_feedback_male");
        setSelectedHairHealthData("dandruff_feedback_male");
        setIsDandruffFeedback(true);
      }
      if (
        userInfo.order_count >= 7 &&
        userInfo.order_count <= 12 &&
        (userAnalysis.stage === "Stage-3" ||
          userAnalysis.stage === "Stage-4" ||
          userAnalysis.stage === "Stage-5")
      ) {
        hairHealthArr.push("hair_growth_male");
        setSelectedHairHealthData("hair_growth_male");
        setIsHairGrowth(true);
      }
      if (
        (userAnalysis.stage === "Stage-1" ||
          userAnalysis.stage === "Stage-2") &&
        userInfo.order_count === 6
      ) {
        hairHealthArr.push("hair_feedback_male");
        setSelectedHairHealthData("hair_feedback_male");
        setIsHairFeedback(true);
      }
      if (
        (userAnalysis.stage === "Stage-1" ||
          userAnalysis.stage === "Stage-2") &&
        userInfo.order_count >= 3 &&
        userInfo.order_count <= 5
      ) {
        hairHealthArr.push("hair_loss_male");
        setSelectedHairHealthData("hair_loss_male");
        setIsHairLoss(true);
      }
      if (
        (userAnalysis.stage === "Stage-3" ||
          userAnalysis.stage === "Stage-4" ||
          userAnalysis.stage === "Stage-5") &&
        userInfo.order_count >= 4 &&
        userInfo.order_count <= 6
      ) {
        hairHealthArr.push("hair_loss_male");
        setSelectedHairHealthData("hair_loss_male");
        setIsHairLoss(true);
      }
      if (
        (userAnalysis.stage === "Stage-6" ||
          userAnalysis.stage === "Coin Size Patch") &&
        userInfo.order_count >= 5 &&
        userInfo.order_count <= 8
      ) {
        hairHealthArr.push("hair_loss_male");
        setSelectedHairHealthData("hair_loss_male");
        setIsHairLoss(true);
      }
      if (!visible && userInfo.order_count >= 1 && userInfo.order_count <= 6) {
        hairHealthArr.push("scalp_feedback_male");
        setSelectedHairHealthData("scalp_feedback_male");
        setIsScalp(true);
      }
      hairHealthArr.reverse();
    }

    if (userAnalysis.gender === "F") {
      if (
        concernVisible &&
        userInfo.order_count >= 1 &&
        userInfo.order_count <= 8
      ) {
        hairHealthArr.push("concerns_female");
        setSelectedHairHealthData("concerns_female");
      }
      if (visible && userInfo.order_count >= 1 && userInfo.order_count <= 2) {
        hairHealthArr.push("dandruff_feedback_female");
        setSelectedHairHealthData("dandruff_feedback_female");
        setIsDandruffFeedback(true);
      }
      if (userInfo.order_count >= 1 && userInfo.order_count <= 3) {
        hairHealthArr.push("hair_feedback_female");
        setSelectedHairHealthData("hair_feedback_female");
        setIsHairFeedback(true);
      }
      if (userInfo.order_count >= 4 && userInfo.order_count <= 12) {
        hairHealthArr.push("hair_breakage");
        setSelectedHairHealthData("hair_breakage");
        setIsHairBreakage(true);
      }
      if (userInfo.order_count >= 6 && userInfo.order_count <= 12) {
        hairHealthArr.push("hair_growth_female");
        setSelectedHairHealthData("hair_growth_female");
        setIsHairGrowth(true);
      }
      if (userInfo.order_count >= 3 && userInfo.order_count <= 5) {
        hairHealthArr.push("hair_loss_female");
        setSelectedHairHealthData("hair_loss_female");
        setIsHairLoss(true);
      }
      if (!visible && userInfo.order_count >= 1 && userInfo.order_count <= 6) {
        hairHealthArr.push("scalp_feedback_female");
        setSelectedHairHealthData("scalp_feedback_female");
        setIsScalp(true);
      }
      hairHealthArr.reverse();
    }
    if (overallHealthArr.length === 0) {
      setIsOverallHealthEmpty(true);
    } else if (overallHealthArr.length !== 0) {
      setIsOverallHealthEmpty(false);
    }
    if (hairHealthArr.length === 0) {
      setIsHairHealthEmpty(true);
    } else if (hairHealthArr.length !== 0) {
      setIsHairHealthEmpty(false);
    }
    setOverallHealthArray(overallHealthArr);
    setHairHealthArray(hairHealthArr);
  }, [userInfo, recommendation, userAnalysis]);

  const onSubmit = async () => {
    let feedbackObj = {
      mood: mood,
      customer_adherence: customerAdherence,
      adherence_reason: adherenceReason,
      gut_health: gutHealth,
      energy: energy,
      hair_loss: hairLoss,
      hair_growth: hairGrowth,
      any_product_reaction: anyProductReaction,
      product_reaction: productReaction,
      not_ordering_reason: notOrderingReason,
      comment: comment,
      diet: diet,
      constipation: constipation.toString(),
      digestion: digestion.toString(),
      overall_health: overallHealth.toString(),
      scalp_feedback: scalpFeedback.toString(),
      hair_feedback: hairFeedback,
      hair_breakage: hairBreakage,
      dandruff_feedback: dandruffFeedback.toString(),
      concerns: concerns,
    };
    // let engagementId = userInfo?.engagement[0]?.id
    //   ? userInfo?.engagement[0]?.id
    //   : "undefined";

    // const res = await engagementFeedback(feedbackObj, engagementId);
    const res = await engagementFeedback(feedbackObj, id);
    const data = await res.json();
    if (res.status !== 200) {
      message.error("Unable to Update Feedback. Please try again!");
      return;
    } else if (res.status === 200) {
      setCartLink(data.next_cart_link);
      setNewCartLink(data.next_cart_link_3_months);
      window.localStorage.setItem(
        "recommendations",
        JSON.stringify(data.recommendations)
      );
      setCaseId(data.case_id);
      setIsSubmitted(true);
      form.resetFields();
    }
  };

  if (isSubmitted) {
    if (dandruffFeedback === 1 || hairLoss === 1 || concerns === "yes") {
      history.push(`/thank-you/slot-booking/${id}`);
    } else {
      history.replace(`/thank-you-cart/${id}`);
    }
    window.localStorage.setItem("next_cart_link", cartLink);
    window.localStorage.setItem("next_cart_link_3_months", newCartLink);
    window.localStorage.setItem("case_id", caseId);
    window.localStorage.setItem("engagement_id", id);
  }

  const handleFieldChange = (e) => {
    const _fields = form.getFieldsValue();
    const _field = e[0];
    if ("comment" in _fields) {
      const _hasUndefinedValues = Object.values(_fields).some((value) => {
        return typeof value === "undefined" || value === null;
      });
      handleShowFields("submitBtn", !_hasUndefinedValues);
    }

    switch (_field?.name[0]) {
      case "customer_adherence": {
        handleShowFields("submitBtn", false);
        handleShowFields("scores", true);
        if ("any_product_reaction" in _fields) {
          delete _fields["any_product_reaction"];
          form.setFieldsValue({ any_product_reaction: null });
        }

        if ("product_reaction" in _fields) {
          delete _fields["product_reaction"];
          form.setFieldsValue({ product_reaction: null });
        }

        if ("adherence_reason" in _fields) {
          delete _fields["adherence_reason"];
          form.setFieldsValue({ adherence_reason: null });
        }

        if ("product_reaction" in showFields) {
          handleShowFields("product_reaction", false);
        }

        if (_field.value === "regular") {
          if ("adherence_reason" in _fields) {
            delete _fields["adherence_reason"];
            form.setFieldsValue({ adherence_reason: null });
          }

          handleShowFields("adherence_reason", false);
          handleShowFields("any_product_reaction", true);
        } else if (_field.value === "irregular") {
          handleShowFields("adherence_reason", true);
          handleShowFields("any_product_reaction", false);
        }
        break;
      }
      // case "diet":
      //   setBtnVisible(true);
      //   break;

      case "adherence_reason": {
        if ("any_product_reaction" in _fields) {
          delete _fields["any_product_reaction"];
          form.setFieldsValue({ any_product_reaction: null });
        }

        if ("product_reaction" in _fields) {
          delete _fields["product_reaction"];
          form.setFieldsValue({ product_reaction: null });
        }

        if ("product_reaction" in showFields) {
          handleShowFields("product_reaction", false);
        }

        handleShowFields("submitBtn", false);
        handleShowFields("any_product_reaction", true);
        break;
      }

      // case "any_product_reaction":
      //   if (_field.value === "yes") {
      //     handleShowFields("submitBtn", false);
      //     handleShowFields("scores", false);
      //     handleShowFields("not_ordering_reason", false);
      //     handleShowFields("comment", false);
      //     handleShowFields("product_reaction", true);
      //     setBtnVisible(false);
      //   } else if (_field.value === "no") {
      //     if ("product_reaction" in _fields) {
      //       delete _fields["product_reaction"];
      //       form.setFieldsValue({ product_reaction: null });
      //       setBtnVisible(true);
      //     }
      //     setBtnVisible(true);
      //     handleShowFields("scores", true);
      //     handleShowFields("product_reaction", false);
      //   }
      //   break;

      case "gut_health":
        if (_field.value === 1 || _field.value === 5) {
          handleShowFields("constipation", true);
          handleShowFields("digestion", true);
        } else if (
          recommendation.Digest_Boost &&
          (_field.value === 1 || _field.value === 5)
        ) {
          handleShowFields("constipation", true);
          handleShowFields("digestion", false);
        } else if (_field.value === 10) {
          handleShowFields("constipation", false);
          handleShowFields("digestion", false);
        }
        break;

      case "overall_health":
        if (_field.value === 1) {
          handleShowFields("comment", true);
        } else if (_field.value === 10 || _field.value === 5) {
          handleShowFields("comment", false);
        }
      case "product_reaction":
        handleShowFields("scores", true);
        break;

      // case "scores":
      //   setBtnVisible(false);
      //   break;

      case "hair_growth":
        handleShowFields("submitBtn", true);
        break;

      case "hair_loss":
        handleShowFields("submitBtn", true);
        if (_field.value !== 1) {
          handleShowFields("concerns", true);
          setConcernVisible(true);
        } else {
          handleShowFields("concerns", false);
          setConcernVisible(false);
        }
        break;

      case "dandruff_feedback":
        if (_field.value !== 1) {
          handleShowFields("concerns", true);
          setConcernVisible(true);
        } else {
          handleShowFields("concerns", false);
          setConcernVisible(false);
        }
        break;
      case "concerns":
        handleShowFields("submitBtn", false);
        if (_field.value === "yes") {
          // setBookingBtn(true);
          handleShowFields("submitBtn", false);
        } else if (_field.value === "no") {
          // setBookingBtn(false);
          handleShowFields("submitBtn", true);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = ({ target }) => {
    if (target.name === "customer_adherence") {
      setTimeout(() => {
        setCustomerAdherence(target.value);
        setSelectedConsistencyData("diet");
      }, 300);
    } else if (target.name === "diet") {
      setTimeout(() => {
        setDiet(target.value);
        if (isOverallHealthEmpty && isHairHealthEmpty) {
          setBtnVisible(true);
        } else if (!isOverallHealthEmpty) {
          setSelectedTags("OVERALL HEALTH");
          handleShowFields("scores", true);
          setBtnVisible(false);
        } else {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
          setBtnVisible(false);
        }
      }, 300);
    } else if (target.name === "any_product_reaction") {
      setTimeout(() => {
        setAnyProductReaction(target.value);
      }, 300);
    } else if (target.name === "product_reaction") {
      setTimeout(() => {
        setProductReaction(target.value);
        if (target.value !== "") {
          setIsFilled(true);
          // setBtnVisible(true);
        }
      }, 300);
    } else if (target.name === "adherence_reason") {
      setTimeout(() => {
        setAdherenceReason(target.value);
      }, 300);
    } else if (target.name === "gut_health") {
      setTimeout(() => {
        setGutHealth(target.value);
      }, 300);
    } else if (target.name === "constipation") {
      setTimeout(() => {
        setConstipation(target.value);
        window.localStorage.setItem("gut_health", target.value);
        if (recommendation.Digest_Boost === "Yes") {
          setSelectedOverallHealthData("digest_boost");
          if (isHairHealthEmpty) {
            setBtnVisible(true);
          }
        } else if (
          recommendation.Health_tatva === "Yes" &&
          userInfo.order_count >= 1 &&
          userInfo.order_count <= 5
        ) {
          setSelectedOverallHealthData("health_tatva");
          if (isHairHealthEmpty) {
            setBtnVisible(true);
          }
        } else if (
          recommendation.Nasya === "Yes" &&
          userInfo.order_count >= 1 &&
          userInfo.order_count <= 6
        ) {
          setSelectedOverallHealthData("nasya");
          if (isHairHealthEmpty) {
            setBtnVisible(true);
          }
        } else {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
        }
      }, 300);
    } else if (target.name === "digestion") {
      setTimeout(() => {
        setDigestion(target.value);
        window.localStorage.setItem("gut_health", target.value);
        if (
          recommendation.Health_tatva === "Yes" &&
          userInfo.order_count >= 1 &&
          userInfo.order_count <= 5
        ) {
          setSelectedOverallHealthData("health_tatva");
          if (isHairHealthEmpty) {
            setBtnVisible(true);
          }
        } else if (
          recommendation.Nasya === "Yes" &&
          userInfo.order_count >= 1 &&
          userInfo.order_count <= 6
        ) {
          setSelectedOverallHealthData("nasya");
          if (isHairHealthEmpty) {
            setBtnVisible(true);
          }
        } else {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
        }
      }, 300);
    } else if (target.name === "energy") {
      setTimeout(() => {
        setEnergy(target.value);
        window.localStorage.setItem("metabolism", target.value);
        if (
          recommendation.Nasya === "Yes" &&
          userInfo.order_count >= 1 &&
          userInfo.order_count <= 6
        ) {
          setSelectedOverallHealthData("nasya");
        } else if (isHairHealthEmpty) {
          setBtnVisible(true);
        } else {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
        }
      }, 300);
    } else if (target.name === "mood") {
      setTimeout(() => {
        setMood(target.value);
        window.localStorage.setItem("mood", target.value);
        if (isHairHealthEmpty) {
          setBtnVisible(true);
        } else if (!isHairHealthEmpty) {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
        }
      }, 300);
    } else if (target.name === "overall_health") {
      setTimeout(() => {
        setOverallHealth(target.value);
        if (target.value === 1) {
          setSelectedTags("OVERALL HEALTH");
        } else if (target.value === 5 || target.value === 10) {
          setSelectedTags("HAIR HEALTH");
          handleShowFields("hairScore", true);
        }
        if (isHairHealthEmpty) {
          setBtnVisible(true);
        }
      }, 300);
    } else if (target.name === "comment") {
      setTimeout(() => {
        setComment(target.value);
      }, 300);
    } else if (target.name === "not_ordering_reason") {
      setTimeout(() => {
        setNotOrderingReason(target.value);
      }, 300);
    } else if (target.name === "scalp_feedback") {
      setTimeout(() => {
        setScalpFeedback(target.value);
        if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
          if (isHairLoss) {
            setSelectedHairHealthData("hair_loss_male");
          } else if (isHairFeedback) {
            setSelectedHairHealthData("hair_feedback_male");
          } else if (isHairGrowth) {
            setSelectedHairHealthData("hair_growth_male");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_male");
          }
        } else if (userAnalysis.gender === "F") {
          if (isHairLoss) {
            setSelectedHairHealthData("hair_loss_female");
          } else if (isHairGrowth) {
            setSelectedHairHealthData("hair_growth_female");
          } else if (isHairBreakage) {
            setSelectedHairHealthData("hair_breakage");
          } else if (isHairFeedback) {
            setSelectedHairHealthData("hair_feedback_female");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_female");
          }
        }
      }, 300);
    } else if (target.name === "hair_loss") {
      setTimeout(() => {
        setHairLoss(target.value);
        window.localStorage.setItem("hair_fall", target.value);
        if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
          // if (target.value === 1) {
          //   window.localStorage.setItem("hair_loss", target.value);
          //   setSelectedHairHealthData("hair_loss_male");
          //   setIsShow(false);
          // } else {
          //   setIsShow(true);
          //   setSelectedHairHealthData("concerns_male");
          // }
          if (target.value === 1) {
            window.localStorage.setItem("hair_loss", target.value);
          }
          if (isHairFeedback) {
            setSelectedHairHealthData("hair_feedback_male");
          } else if (isHairGrowth) {
            setSelectedHairHealthData("hair_growth_male");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_male");
          }
        } else if (userAnalysis.gender === "F") {
          // if (target.value === 1) {
          //   setSelectedHairHealthData("hair_loss_female");
          //   setIsShow(false);
          // } else {
          //   setIsShow(true);
          //   setSelectedHairHealthData("concerns_female");
          // }
          if (target.value === 1) {
            window.localStorage.setItem("hair_loss", target.value);
          }
          if (isHairGrowth) {
            setSelectedHairHealthData("hair_growth_female");
          } else if (isHairBreakage) {
            setSelectedHairHealthData("hair_breakage");
          } else if (isHairFeedback) {
            setSelectedHairHealthData("hair_feedback_female");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_female");
          }
        }
      }, 300);
    } else if (target.name === "hair_growth") {
      setTimeout(() => {
        setHairGrowth(target.value);
        if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
          if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback");
          } else if (isShow) {
            setSelectedHairHealthData("concerns_male");
          }
        } else if (userAnalysis.gender === "F") {
          if (isHairBreakage) {
            setSelectedHairHealthData("hair_breakage");
          } else if (isHairFeedback) {
            setSelectedHairHealthData("hair_feedback_female");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_female");
          } else if (isShow) {
            setSelectedHairHealthData("concerns_female");
          }
        }
      }, 300);
    } else if (target.name === "hair_breakage") {
      setTimeout(() => {
        setHairBreakage(target.value);
        if (isHairFeedback) {
          setSelectedHairHealthData("hair_feedback_female");
        } else if (isDandruffFeedback) {
          setSelectedHairHealthData("dandruff_feedback_female");
        } else if (isShow) {
          setSelectedHairHealthData("concerns_female");
        }
      }, 300);
    } else if (target.name === "hair_feedback") {
      setTimeout(() => {
        setHairFeedback(target.value);
        if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
          if (isHairGrowth) {
            setSelectedHairHealthData("hair_growth_male");
          } else if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_male");
          } else if (isShow) {
            setSelectedHairHealthData("concerns_male");
          }
        } else if (userAnalysis.gender === "F") {
          if (isDandruffFeedback) {
            setSelectedHairHealthData("dandruff_feedback_female");
          } else if (isShow) {
            setSelectedHairHealthData("concerns_female");
          }
        }
      }, 300);
    } else if (target.name === "dandruff_feedback") {
      setTimeout(() => {
        setDandruffFeedback(target.value);
        window.localStorage.setItem("dandruff", target.value);
        if (userAnalysis.gender === "M" || userAnalysis.gender === "null") {
          // if (target.value === 1) {
          //   window.localStorage.setItem("dandruff_feedback", target.value);
          //   setSelectedHairHealthData("dandruff_feedback_male");
          //   setIsShow(false);
          // } else {
          //   setIsShow(true);
          //   setSelectedHairHealthData("concerns_male");
          // }
          if (target.value === 1) {
            window.localStorage.setItem("dandruff_feedback", target.value);
          }
        } else if (userAnalysis.gender === "F") {
          // if (target.value === 1) {
          //   window.localStorage.setItem("dandruff_feedback", target.value);
          //   setSelectedHairHealthData("dandruff_feedback_female");
          //   setIsShow(false);
          // } else {
          //   setIsShow(true);
          //   setSelectedHairHealthData("concerns_female");
          // }
          if (target.value === 1) {
            window.localStorage.setItem("dandruff_feedback", target.value);
          }
        }
      }, 300);
    } else if (target.name === "concerns") {
      setTimeout(() => {
        setConcerns(target.value);
        window.localStorage.setItem("concerns", target.value);
      }, 300);
    }
  };

  const keys = Object.keys(userAnalysis);
  let rootCause = "";
  let rootCauseArr = [];
  keys.map((key) => {
    switch (key) {
      case "sleep":
        rootCause = "Sleep";
        break;
      case "stress":
        rootCause = "Stress";
        break;
      case "gut_health":
        rootCause = "Gut Health";
        break;
      case "energy":
        rootCause = "Energy";
        break;
      default:
        return;
    }
    rootCauseArr.push(rootCause);
  });

  return (
    <>
      <Layout style={{ backgroundColor: "white" }}>
        {/* <Row align="middle" justify="center">
          <div style={{ width: "100%", backgroundColor: "rgb(65,64,66)" }}>
            <img
              src={trayaLogo}
              alt="Logo"
              className="trayalogo"
              style={{ cursor: "pointer" }}
              width={140}
            />
          </div>
        </Row> */}
        <Row
          align="middle"
          justify="center"
          style={{
            paddingBottom: 5,
            width: "100%",
          }}
          className="mt-4 "
        >
          <Form
            form={form}
            layout="vertical"
            onFieldsChange={handleFieldChange}
            onFinish={() => onSubmit()}
          >
            <Row style={{ width: "100%" }}>
              <Col className="mainContainer">
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {tagsData.map((tag) => (
                    <div
                      key={tag}
                      className={
                        tag == selectedTags ? "activeTag" : "inactiveTag"
                      }
                    >
                      {tag}
                    </div>
                  ))}
                </div> */}
                {selectedTags == "CONSISTENCY" && (
                  <Row>
                    {" "}
                    <Row
                      style={{
                        marginTop: 10,
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      {/* <div className="progressTagContainer-1">
                        {consistencyData.map((tag) => (
                          <div
                            key={tag}
                            className={
                              tag == selectedConsistencyData
                                ? "activeTag-1"
                                : "inactiveTag-1"
                            }
                          ></div>
                        ))}
                      </div> */}
                      <div
                        // style={{ margin: "0 auto" }}
                        style={{ width: "100%" }}
                      >
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {selectedConsistencyData ==
                              "customer_adherence" && (
                              <>
                                <div
                                  className="introPageContainer"
                                  // className="tagContainer"
                                >
                                  <Row align="left" justify="start">
                                    <Row
                                      justify="start"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        backgroundColor: "white",
                                        // textAlign: "center",
                                        // textTransform:"uppercase",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <div
                                        style={{ margin: "0 auto" }}
                                        className="userDetailBox"
                                      >
                                        <div className="userContainer-1">
                                          <div
                                            style={{
                                              width: "250px",
                                              height: "29px",
                                              marginTop: "-2%",
                                              textAlign: "left",
                                              marginLeft: "-2%",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontFamily:
                                                  "Nunito Sans, sans-serif",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                                fontSize: "18px",
                                                lineHeight: "22px",
                                                color: "#414042",
                                              }}
                                            >
                                              {userAnalysis.first_name}
                                            </span>
                                            {userAnalysis.age && (
                                              <span
                                                style={{
                                                  fontFamily:
                                                    "Nunito Sans, sans-serif",
                                                  fontStyle: "italic",
                                                  fontWeight: "400",
                                                  fontSize: "18px",
                                                  lineHeight: "22px",
                                                  color: "#414042",
                                                }}
                                              >
                                                {", "}
                                                {userAnalysis.age}
                                              </span>
                                            )}
                                            {/* {userAnalysis.gender && (
                                            <span
                                              style={{
                                                fontFamily:
                                                  "Nunito Sans, sans-serif",
                                                fontStyle: "italic",
                                                fontWeight: "400",
                                                fontSize: "18px",
                                                lineHeight: "22px",
                                                color: "#414042",
                                              }}
                                            >
                                              {", "}
                                              {userAnalysis.gender === "M"
                                                ? "Male"
                                                : "Female"}
                                            </span>
                                          )} */}
                                          </div>

                                          <div
                                            style={{
                                              width: "90px",
                                              height: "55px",
                                              marginTop: "10px",
                                              textAlign: "right",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "90px",
                                                height: "25px",
                                                marginTop: "-18px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontFamily:
                                                    "Nunito Sans, sans-serif",
                                                  fontStyle: "normal",
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  lineHeight: "19px",
                                                  color: "#000000",
                                                }}
                                              >
                                                {moment(
                                                  data.delivery_date
                                                ).format("DD MMM YYYY")}
                                              </span>
                                            </div>
                                            <div
                                              style={{
                                                width: "90px",
                                                height: "25px",
                                                marginTop: "-6px",
                                                marginLeft: "7px",
                                              }}
                                            >
                                              {data.order_display_id && (
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      "Nunito Sans, sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    fontSize: "9px",
                                                    lineHeight: "12px",
                                                    color: "#414042",
                                                  }}
                                                >
                                                  Order ID:{" "}
                                                  {data.order_display_id}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="diagnosisContainer">
                                          <div
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            <div className="diagnosis-1">
                                              <span
                                                style={{
                                                  fontSize: "11px",
                                                  lineHeight: "17px",
                                                }}
                                              >
                                                Current Diagnosis:
                                              </span>
                                            </div>
                                            <div className="diagnosisBar-1"></div>
                                            <div className="diagnosisDetails-1">
                                              {userAnalysis.gender === "M" && (
                                                <>
                                                  {recommendation.Type_of_hair_loss && (
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        fontStyle: "normal",
                                                        fontSize: "13px",
                                                        lineHeight: "19px",
                                                      }}
                                                    >
                                                      {
                                                        recommendation.Type_of_hair_loss
                                                      }
                                                    </span>
                                                  )}
                                                  {userAnalysis.stage && (
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        fontStyle: "normal",
                                                        fontSize: "13px",
                                                        lineHeight: "19px",
                                                      }}
                                                    >
                                                      {", "}
                                                      {userAnalysis.stage}
                                                    </span>
                                                  )}
                                                </>
                                              )}

                                              {userAnalysis.gender === "F" && (
                                                <>
                                                  <span
                                                    style={{
                                                      fontWeight: "600",
                                                      fontStyle: "normal",
                                                      fontSize: "13px",
                                                      lineHeight: "19px",
                                                    }}
                                                  >
                                                    Female pattern hair loss
                                                  </span>
                                                  {userAnalysis.stages && (
                                                    <span
                                                      style={{
                                                        fontWeight: "600",
                                                        fontStyle: "normal",
                                                        fontSize: "13px",
                                                        lineHeight: "19px",
                                                      }}
                                                    >
                                                      {", "}
                                                      {userAnalysis.stages}
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          <div className="diagnosisBar"></div>
                                          <div
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            <div className="diagnosis-2">
                                              <span
                                                style={{
                                                  fontSize: "11px",
                                                  lineHeight: "17px",
                                                }}
                                              >
                                                Root causes affecting hair loss:
                                              </span>
                                            </div>
                                            <div className="diagnosisBar-2 "></div>
                                            <div className="diagnosisDetails-2">
                                              {rootCauseArr.map((root, i) => {
                                                return (
                                                  <span
                                                    key={i}
                                                    style={{
                                                      fontWeight: "600",
                                                      fontStyle: "normal",
                                                      fontSize: "13px",
                                                      lineHeight: "19px",
                                                    }}
                                                  >
                                                    {i > 0 && ", "}

                                                    {root}
                                                  </span>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <span
                                      style={{
                                        fontFamily: "Nunito Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "22px",
                                        lineHeight: "22px",
                                        color: "#414042",
                                        marginBottom:"10px"
                                      }}
                                    >
                                      Hey {userAnalysis.first_name}! {" "}
                                    </span> &nbsp;&nbsp;
                                    <span
                                      style={{
                                        fontFamily: "Nunito Sans, sans-serif",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "22px",
                                        lineHeight: "22px",
                                        color: "#414042",
                                      }}
                                    >
                                Please share your feedback.
                                    </span> */}
                                    </Row>
                                  </Row>
                                  {/* <br /> */}
                                  <span className="header">
                                    Were you regular with your hair routine this
                                    month?
                                  </span>
                                  <br />
                                  <span className="sub-header">
                                    Not taken a gap of more than 5 days during
                                    the month
                                  </span>
                                  {/* <br /> */}
                                  <Form.Item name="customer_adherence">
                                    <Radio.Group
                                      buttonStyle="outline"
                                      size="large"
                                      onChange={handleChange}
                                      name="customer_adherence"
                                      style={{
                                        fontFamily: "Nunito Sans",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        color: "#3A424F",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Space direction="vertical">
                                        <Radio
                                          onClick={handleChange}
                                          value="yes"
                                        >
                                          Yes
                                        </Radio>
                                        <div className="radioBar"></div>
                                        <Radio
                                          onClick={handleChange}
                                          value="no"
                                        >
                                          No
                                        </Radio>
                                        <div className="radioBar"></div>
                                      </Space>
                                    </Radio.Group>
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </div>
                          {selectedConsistencyData == "diet" && (
                            <>
                              <div className="tagContainer">
                                <span className="header">
                                  Were you able to follow Traya diet to some
                                  extent this month ?
                                </span>
                                <Form.Item name="diet">
                                  <Radio.Group
                                    buttonStyle="outline"
                                    size="large"
                                    onChange={handleChange}
                                    name="diet"
                                    style={{
                                      fontFamily: "Nunito Sans",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      color: "#3A424F",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <Space direction="vertical">
                                      <Radio onClick={handleChange} value="yes">
                                        Yes
                                      </Radio>
                                      <div className="radioBar"></div>
                                      <Radio onClick={handleChange} value="no">
                                        No
                                      </Radio>
                                      <div className="radioBar"></div>
                                    </Space>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                              <div className="btnContainer">
                                <div style={{ color: "#9BBA70" }}>
                                  <Button
                                    onClick={() =>
                                      setSelectedConsistencyData(
                                        "customer_adherence"
                                      )
                                    }
                                    className="previousBtn"
                                  >
                                    <LeftOutlined
                                      style={{
                                        fontSize: 15,
                                        color: "#9BBA70",
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "#9BBA70",
                                      }}
                                    >
                                      PREVIOUS
                                    </span>
                                  </Button>
                                </div>
                              </div>
                              {btnVisible && (
                                <div className="nextBtn">
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="resultBtn"
                                  >
                                    SEE MY RESULTS
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </Space>
                      </div>
                    </Row>
                  </Row>
                )}
                {selectedTags == "OVERALL HEALTH" && (
                  <Row>
                    <Row
                      style={{
                        marginTop: 10,
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      <div className="progressTagContainer-2">
                        {overallHealthArray.map((tag) => (
                          <div
                            key={tag}
                            className={
                              tag == selectedOverallHealthData
                                ? "activeTag-2"
                                : "inactiveTag-2"
                            }
                          ></div>
                        ))}
                      </div>

                      <div style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          {showFields.scores && (
                            <>
                              {selectedOverallHealthData === "gut_shuddi" && (
                                <>
                                  {userInfo.order_count >= 1 &&
                                    userInfo.order_count < 5 && (
                                      <div className="tagContainer">
                                        <span className="header">
                                          How is your constipation now?
                                        </span>
                                        <Form.Item name="constipation">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="constipation"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                  {userInfo.order_count === 5 && (
                                    <div className="tagContainer">
                                      <span className="header">
                                        You have completed the course of using
                                        Gut Shuddi. How is your digestion now?
                                      </span>
                                      <Form.Item name="constipation">
                                        <Radio.Group
                                          buttonStyle="outline"
                                          size="large"
                                          onChange={handleChange}
                                          name="constipation"
                                          style={{
                                            fontFamily: "Nunito Sans",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            color: "#3A424F",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Space direction="vertical">
                                            <Radio
                                              onClick={handleChange}
                                              value={10}
                                            >
                                              Better
                                            </Radio>
                                            <div className="radioBar"></div>
                                            <Radio
                                              onClick={handleChange}
                                              value={5}
                                            >
                                              No Change
                                            </Radio>
                                            <div className="radioBar"></div>
                                            <Radio
                                              onClick={handleChange}
                                              value={1}
                                            >
                                              Worse
                                            </Radio>
                                            <div className="radioBar"></div>
                                          </Space>
                                        </Radio.Group>
                                      </Form.Item>
                                    </div>
                                  )}
                                  <div className="btnContainer">
                                    <div style={{ color: "#9BBA70" }}>
                                      <Button
                                        onClick={() =>
                                          setSelectedTags("CONSISTENCY")
                                        }
                                        className="previousBtn"
                                      >
                                        <LeftOutlined
                                          style={{
                                            fontSize: 15,
                                            color: "#9BBA70",
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "#9BBA70",
                                          }}
                                        >
                                          PREVIOUS
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                  {btnVisible && (
                                    <div className="nextBtn">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resultBtn"
                                      >
                                        SEE MY RESULTS
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                              {selectedOverallHealthData === "digest_boost" && (
                                <>
                                  <div className="tagContainer">
                                    <span className="header">
                                      How is bloating, gas, acidity now ?
                                    </span>
                                    <Form.Item name="digestion">
                                      <Radio.Group
                                        buttonStyle="outline"
                                        size="large"
                                        onChange={handleChange}
                                        name="digestion"
                                        style={{
                                          fontFamily: "Nunito Sans",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          color: "#3A424F",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Space direction="vertical">
                                          <Radio
                                            onClick={handleChange}
                                            value={10}
                                          >
                                            Better
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={5}
                                          >
                                            No Change
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={1}
                                          >
                                            Worse
                                          </Radio>
                                          <div className="radioBar"></div>
                                        </Space>
                                      </Radio.Group>
                                    </Form.Item>
                                  </div>
                                  <div className="btnContainer">
                                    <div style={{ color: "#9BBA70" }}>
                                      <Button
                                        onClick={() => {
                                          if (
                                            recommendation.Gut_shuddi ===
                                              "Yes" &&
                                            userInfo.order_count >= 1 &&
                                            userInfo.order_count <= 5
                                          ) {
                                            setSelectedOverallHealthData(
                                              "gut_shuddi"
                                            );
                                          } else {
                                            setSelectedTags("CONSISTENCY");
                                          }
                                        }}
                                        className="previousBtn"
                                      >
                                        <LeftOutlined
                                          style={{
                                            fontSize: 15,
                                            color: "#9BBA70",
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "#9BBA70",
                                          }}
                                        >
                                          PREVIOUS
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                  {btnVisible && (
                                    <div className="nextBtn">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resultBtn"
                                      >
                                        SEE MY RESULTS
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                              {selectedOverallHealthData === "health_tatva" && (
                                <>
                                  {userInfo.order_count >= 1 &&
                                    userInfo.order_count < 5 && (
                                      <div className="tagContainer">
                                        <span className="header">
                                          Are you feeling lighter and more
                                          energetic than before?
                                        </span>
                                        <Form.Item name="energy">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="energy"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                  {userInfo.order_count === 5 && (
                                    <div className="tagContainer">
                                      <span className="header">
                                        You have completed the course of using
                                        Health Tatva . How are your energy
                                        levels now?
                                      </span>
                                      <Form.Item name="energy">
                                        <Radio.Group
                                          buttonStyle="outline"
                                          size="large"
                                          onChange={handleChange}
                                          name="energy"
                                          style={{
                                            fontFamily: "Nunito Sans",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            color: "#3A424F",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Space direction="vertical">
                                            <Radio
                                              onClick={handleChange}
                                              value={10}
                                            >
                                              Better
                                            </Radio>
                                            <div className="radioBar"></div>
                                            <Radio
                                              onClick={handleChange}
                                              value={5}
                                            >
                                              No Change
                                            </Radio>
                                            <div className="radioBar"></div>
                                            <Radio
                                              onClick={handleChange}
                                              value={1}
                                            >
                                              Worse
                                            </Radio>
                                            <div className="radioBar"></div>
                                          </Space>
                                        </Radio.Group>
                                      </Form.Item>
                                    </div>
                                  )}
                                  <div className="btnContainer">
                                    <div style={{ color: "#9BBA70" }}>
                                      <Button
                                        onClick={() => {
                                          if (
                                            recommendation.Digest_Boost ===
                                            "Yes"
                                          ) {
                                            setSelectedOverallHealthData(
                                              "digest_boost"
                                            );
                                          }
                                          if (
                                            recommendation.Gut_shuddi ===
                                              "Yes" &&
                                            userInfo.order_count >= 1 &&
                                            userInfo.order_count <= 5
                                          ) {
                                            setSelectedOverallHealthData(
                                              "gut_shuddi"
                                            );
                                          } else {
                                            setSelectedTags("CONSISTENCY");
                                          }
                                        }}
                                        className="previousBtn"
                                      >
                                        <LeftOutlined
                                          style={{
                                            fontSize: 15,
                                            color: "#9BBA70",
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "#9BBA70",
                                          }}
                                        >
                                          PREVIOUS
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                  {btnVisible && (
                                    <div className="nextBtn">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resultBtn"
                                      >
                                        SEE MY RESULTS
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                              {selectedOverallHealthData === "nasya" && (
                                <>
                                  <div className="tagContainer">
                                    <span className="header">
                                      How is your overall mood and sleep these
                                      days ?
                                    </span>
                                    <Form.Item name="mood">
                                      <Radio.Group
                                        buttonStyle="outline"
                                        size="large"
                                        onChange={handleChange}
                                        name="mood"
                                        style={{
                                          fontFamily: "Nunito Sans",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          color: "#3A424F",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Space direction="vertical">
                                          <Radio
                                            onClick={handleChange}
                                            value={10}
                                          >
                                            Better
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={5}
                                          >
                                            No change
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={1}
                                          >
                                            Worse
                                          </Radio>
                                          <div className="radioBar"></div>
                                        </Space>
                                      </Radio.Group>
                                    </Form.Item>
                                  </div>
                                  <div className="btnContainer">
                                    <div style={{ color: "#9BBA70" }}>
                                      <Button
                                        onClick={() => {
                                          if (
                                            recommendation.Health_tatva ===
                                              "Yes" &&
                                            userInfo.order_count >= 1 &&
                                            userInfo.order_count <= 5
                                          ) {
                                            setSelectedOverallHealthData(
                                              "health_tatva"
                                            );
                                          } else if (
                                            recommendation.Digest_Boost ===
                                            "Yes"
                                          ) {
                                            setSelectedOverallHealthData(
                                              "digest_boost"
                                            );
                                          } else if (
                                            recommendation.Gut_shuddi ===
                                              "Yes" &&
                                            userInfo.order_count >= 1 &&
                                            userInfo.order_count <= 5
                                          ) {
                                            setSelectedOverallHealthData(
                                              "gut_shuddi"
                                            );
                                          } else {
                                            setSelectedTags("CONSISTENCY");
                                          }
                                        }}
                                        className="previousBtn"
                                      >
                                        <LeftOutlined
                                          style={{
                                            fontSize: 15,
                                            color: "#9BBA70",
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "#9BBA70",
                                          }}
                                        >
                                          PREVIOUS
                                        </span>
                                      </Button>
                                    </div>
                                  </div>
                                  {btnVisible && (
                                    <div className="nextBtn">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resultBtn"
                                      >
                                        SEE MY RESULTS
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                              {selectedOverallHealthData ===
                                "overall_health" && (
                                <>
                                  <div className="tagContainer">
                                    <span className="header">
                                      How would you rate your Overall Health?
                                    </span>
                                    <Form.Item name="overall_health">
                                      <Radio.Group
                                        buttonStyle="outline"
                                        size="large"
                                        onChange={handleChange}
                                        name="overall_health"
                                        style={{
                                          fontFamily: "Nunito Sans",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          color: "#3A424F",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <Space direction="vertical">
                                          <Radio
                                            onClick={handleChange}
                                            value={10}
                                          >
                                            Good
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={5}
                                          >
                                            Ok
                                          </Radio>
                                          <div className="radioBar"></div>
                                          <Radio
                                            onClick={handleChange}
                                            value={1}
                                          >
                                            Bad
                                          </Radio>
                                          <div className="radioBar"></div>
                                        </Space>
                                      </Radio.Group>
                                    </Form.Item>
                                    {showFields.comment && (
                                      <>
                                        <span className="header">
                                          Anything you would like to share with
                                          your coach?
                                        </span>
                                        <Form.Item name="comment">
                                          <Input
                                            placeholder="Please share your concern"
                                            onChange={handleChange}
                                            name="comment"
                                            bordered={false}
                                            className="commentField"
                                          />
                                        </Form.Item>
                                      </>
                                    )}
                                  </div>
                                  <div className="btnContainer">
                                    <div style={{ color: "#9BBA70" }}>
                                      <Button
                                        onClick={() =>
                                          setSelectedTags("CONSISTENCY")
                                        }
                                        className="previousBtn"
                                      >
                                        <LeftOutlined
                                          style={{
                                            fontSize: 15,
                                            color: "#9BBA70",
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "#9BBA70",
                                          }}
                                        >
                                          PREVIOUS
                                        </span>
                                      </Button>
                                    </div>
                                    {showFields.comment && (
                                      <div>
                                        <Button
                                          onClick={() => {
                                            setSelectedTags("HAIR HEALTH");
                                            handleShowFields("hairScore", true);
                                          }}
                                          className="previousBtn"
                                        >
                                          <ArrowCircleRight />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                  {btnVisible && (
                                    <div className="nextBtn">
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="resultBtn"
                                      >
                                        SEE MY RESULTS
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Space>
                      </div>
                    </Row>
                  </Row>
                )}
                {selectedTags == "HAIR HEALTH" && (
                  <Row>
                    <Row
                      style={{
                        marginTop: 10,
                        fontFamily: "Rubik, sans-serif",
                      }}
                    >
                      <div className="progressTagContainer-3">
                        {hairHealthArray.map((tag) => (
                          <div
                            key={tag}
                            className={
                              tag == selectedHairHealthData
                                ? "activeTag-3"
                                : "inactiveTag-3"
                            }
                          ></div>
                        ))}
                      </div>
                      <div style={{ width: "100%" }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          {showFields.hairScore && (
                            <>
                              {(userAnalysis.gender === "M" ||
                                userAnalysis.gender === "null") && (
                                <>
                                  {selectedHairHealthData ===
                                    "scalp_feedback_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Is there any change in your scalp
                                          health?
                                        </span>
                                        <Form.Item name="scalp_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="scalp_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                I have dandruff/Itching
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isOverallHealthEmpty) {
                                                setSelectedTags("CONSISTENCY");
                                              } else {
                                                setSelectedTags(
                                                  "OVERALL HEALTH"
                                                );
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isHairLoss &&
                                        !isHairFeedback &&
                                        !isHairGrowth &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "hair_loss_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          How is your hair fall now?
                                        </span>
                                        <Form.Item name="hair_loss">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_loss"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback"
                                                );
                                              } else {
                                                if (isOverallHealthEmpty) {
                                                  setSelectedTags(
                                                    "CONSISTENCY"
                                                  );
                                                } else {
                                                  setSelectedTags(
                                                    "OVERALL HEALTH"
                                                  );
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow &&
                                        !isHairFeedback &&
                                        !isHairGrowth &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "hair_feedback_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you feel your hair are now thicker
                                          & healthier than before?
                                        </span>
                                        <Form.Item name="hair_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value="yes"
                                              >
                                                Hurray Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value="no"
                                              >
                                                Not yet
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_male"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_male"
                                                );
                                              } else {
                                                if (isOverallHealthEmpty) {
                                                  setSelectedTags(
                                                    "CONSISTENCY"
                                                  );
                                                } else {
                                                  setSelectedTags(
                                                    "OVERALL HEALTH"
                                                  );
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow &&
                                        !isHairGrowth &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "hair_growth_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you see any new hair growth?
                                        </span>
                                        <Form.Item name="hair_growth">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_growth"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Hurray Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Not yet
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairFeedback) {
                                                setSelectedHairHealthData(
                                                  "hair_feedback_male"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_male"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_male"
                                                );
                                              } else {
                                                if (isOverallHealthEmpty) {
                                                  setSelectedTags(
                                                    "CONSISTENCY"
                                                  );
                                                } else {
                                                  setSelectedTags(
                                                    "OVERALL HEALTH"
                                                  );
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow && !isDandruffFeedback && (
                                        <div className="nextBtn">
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="resultBtn"
                                          >
                                            SEE MY RESULTS
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "dandruff_feedback_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Any change in dandruff ?
                                        </span>
                                        <Form.Item name="dandruff_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="dandruff_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Much Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_male"
                                                );
                                              } else if (isHairFeedback) {
                                                setSelectedHairHealthData(
                                                  "hair_feedback_male"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_male"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_male"
                                                );
                                              } else {
                                                if (isOverallHealthEmpty) {
                                                  setSelectedTags(
                                                    "CONSISTENCY"
                                                  );
                                                } else {
                                                  setSelectedTags(
                                                    "OVERALL HEALTH"
                                                  );
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow && (
                                        <div className="nextBtn">
                                          <div>
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {selectedHairHealthData ===
                                    "concerns_male" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you have any other concerns and
                                          want to get on a call with a certified
                                          hair coach at Traya.
                                        </span>
                                        <Form.Item name="concerns">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="concerns"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value="yes"
                                              >
                                                Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value="no"
                                              >
                                                No
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isDandruffFeedback) {
                                                setSelectedHairHealthData(
                                                  "dandruff_feedback_male"
                                                );
                                              } else if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_male"
                                                );
                                              } else if (isHairFeedback) {
                                                setSelectedHairHealthData(
                                                  "hair_feedback_male"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_male"
                                                );
                                              }
                                            }}
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                              cursor: "pointer",
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="nextBtn">
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          style={{ borderRadius: "5px" }}
                                          className="resultBtn"
                                        >
                                          SEE MY RESULTS
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {userAnalysis.gender === "F" && (
                                <>
                                  {selectedHairHealthData ===
                                    "scalp_feedback_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Is there any change in your scalp
                                          health?
                                        </span>
                                        <Form.Item name="scalp_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="scalp_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                I have dandruff/Itching
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isOverallHealthEmpty) {
                                                setSelectedTags("CONSISTENCY");
                                              } else {
                                                setSelectedTags(
                                                  "OVERALL HEALTH"
                                                );
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isHairLoss &&
                                        !isHairGrowth &&
                                        !isHairBreakage &&
                                        !isHairFeedback &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}

                                  {selectedHairHealthData ===
                                    "hair_loss_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          How is your hair fall now?
                                        </span>
                                        <Form.Item name="hair_loss">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_loss"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>

                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_female"
                                                );
                                              } else {
                                                {
                                                  if (isOverallHealthEmpty) {
                                                    setSelectedTags(
                                                      "CONSISTENCY"
                                                    );
                                                  } else {
                                                    setSelectedTags(
                                                      "OVERALL HEALTH"
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow &&
                                        !isHairGrowth &&
                                        !isHairBreakage &&
                                        !isHairFeedback &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}

                                  {selectedHairHealthData ===
                                    "hair_growth_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you see an increase in your hair
                                          volume?
                                        </span>
                                        <Form.Item name="hair_growth">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_growth"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Not Change yet
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_female"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_female"
                                                );
                                              } else {
                                                {
                                                  if (isOverallHealthEmpty) {
                                                    setSelectedTags(
                                                      "CONSISTENCY"
                                                    );
                                                  } else {
                                                    setSelectedTags(
                                                      "OVERALL HEALTH"
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow &&
                                        !isHairBreakage &&
                                        !isHairFeedback &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "hair_breakage" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Has hair breakage reduced?
                                        </span>
                                        <Form.Item name="hair_breakage">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_breakage"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value="yes"
                                              >
                                                Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value="no"
                                              >
                                                No Change yet
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_female"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_female"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_female"
                                                );
                                              } else {
                                                {
                                                  if (isOverallHealthEmpty) {
                                                    setSelectedTags(
                                                      "CONSISTENCY"
                                                    );
                                                  } else {
                                                    setSelectedTags(
                                                      "OVERALL HEALTH"
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow &&
                                        !isHairFeedback &&
                                        !isDandruffFeedback && (
                                          <div className="nextBtn">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                  {selectedHairHealthData ===
                                    "hair_feedback_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you feel your hair is more
                                          nourshied and healthy now?
                                        </span>
                                        <Form.Item name="hair_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="hair_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value="yes"
                                              >
                                                Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value="no"
                                              >
                                                No
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairBreakage) {
                                                setSelectedHairHealthData(
                                                  "hair_breakage"
                                                );
                                              } else if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_female"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_female"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_female"
                                                );
                                              } else {
                                                {
                                                  if (isOverallHealthEmpty) {
                                                    setSelectedTags(
                                                      "CONSISTENCY"
                                                    );
                                                  } else {
                                                    setSelectedTags(
                                                      "OVERALL HEALTH"
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow && !isDandruffFeedback && (
                                        <div className="nextBtn">
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="resultBtn"
                                          >
                                            SEE MY RESULTS
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {selectedHairHealthData ===
                                    "dandruff_feedback_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Any change in dandruff ?
                                        </span>
                                        <Form.Item name="dandruff_feedback">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="dandruff_feedback"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value={10}
                                              >
                                                Much Better
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={5}
                                              >
                                                No Change
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value={1}
                                              >
                                                Worse
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isHairFeedback) {
                                                setSelectedHairHealthData(
                                                  "hair_feedback_female"
                                                );
                                              } else if (isHairBreakage) {
                                                setSelectedHairHealthData(
                                                  "hair_breakage"
                                                );
                                              } else if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_female"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_female"
                                                );
                                              } else if (isScalp) {
                                                setSelectedHairHealthData(
                                                  "scalp_feedback_female"
                                                );
                                              } else {
                                                {
                                                  if (isOverallHealthEmpty) {
                                                    setSelectedTags(
                                                      "CONSISTENCY"
                                                    );
                                                  } else {
                                                    setSelectedTags(
                                                      "OVERALL HEALTH"
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      {!isShow && (
                                        <div className="nextBtn">
                                          <div>
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              className="resultBtn"
                                            >
                                              SEE MY RESULTS
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {selectedHairHealthData ===
                                    "concerns_female" && (
                                    <>
                                      <div className="tagContainer">
                                        <span className="header">
                                          Do you have any other concerns and
                                          want to get on a call with a certified
                                          hair coach at Traya.
                                        </span>
                                        <Form.Item name="concerns">
                                          <Radio.Group
                                            buttonStyle="outline"
                                            size="large"
                                            onChange={handleChange}
                                            name="concerns"
                                            style={{
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              color: "#3A424F",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                onClick={handleChange}
                                                value="yes"
                                              >
                                                Yes
                                              </Radio>
                                              <div className="radioBar"></div>
                                              <Radio
                                                onClick={handleChange}
                                                value="no"
                                              >
                                                No
                                              </Radio>
                                              <div className="radioBar"></div>
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                      <div className="btnContainer">
                                        <div style={{ color: "#9BBA70" }}>
                                          <Button
                                            onClick={() => {
                                              if (isDandruffFeedback) {
                                                setSelectedHairHealthData(
                                                  "dandruff_feedback_female"
                                                );
                                              } else if (isHairFeedback) {
                                                setSelectedHairHealthData(
                                                  "hair_feedback_female"
                                                );
                                              } else if (isHairBreakage) {
                                                setSelectedHairHealthData(
                                                  "hair_breakage"
                                                );
                                              } else if (isHairGrowth) {
                                                setSelectedHairHealthData(
                                                  "hair_growth_female"
                                                );
                                              } else if (isHairLoss) {
                                                setSelectedHairHealthData(
                                                  "hair_loss_female"
                                                );
                                              }
                                            }}
                                            style={{
                                              border: "none",
                                              backgroundColor: "white",
                                              cursor: "pointer",
                                            }}
                                            className="previousBtn"
                                          >
                                            <LeftOutlined
                                              style={{
                                                fontSize: 15,
                                                color: "#9BBA70",
                                              }}
                                            />
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "#9BBA70",
                                              }}
                                            >
                                              PREVIOUS
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="nextBtn">
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          style={{ borderRadius: "5px" }}
                                          className="resultBtn"
                                        >
                                          SEE MY RESULTS
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Space>
                      </div>
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Layout>
    </>
  );
};

export default NewEngagementFeedback;
