import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { SLOTS_API } from "../../../constants/urls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { SlotBooking } from "../../../components/SlotBooking";
import TrayaLogo from "../../../assets/Traya_Logo.jpg";

export const RescheduleSlots = () => {
  const { id: caseId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [slotBooked, setSlotBooked] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({});
  const [coachName, setCoachName] = useState();

  let engagementId = window.localStorage.getItem("engagement_id");

  const handleBooking = async (_, slotId) => {
    setLoading(true);

    const _res = await fetchRequest(SLOTS_API, {
      method: "POST",
      body: JSON.stringify({ case_id: caseId, slot_id: slotId }),
    });
    setLoading(false);
    if (_res.status === 200) setSlotBooked(true);
    setCoachName(_res.data.first_name);
    return _res;
  };

  const selectedDateTime = useMemo(() => {
    return {
      date: dayjs(selectedInfo.date).format("DD-MM-YYYY"),
      startTime: dayjs(selectedInfo.time).format("hh:mm A"),
      endTime: dayjs(selectedInfo.time).add(30, "minute").format("hh:mm A"),
    };
  }, [selectedInfo]);

  if (slotBooked) {
    history.replace(`/thank-you-final/${engagementId}`);
    window.localStorage.setItem("date", selectedDateTime.date);
    window.localStorage.setItem("startTime", selectedDateTime.startTime);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          minHeight: "100vh",
        }}
      >
        <div className="mt-4" style={{ height: "100%" }}>
          <img src={TrayaLogo} alt="traya health" width={200} />
        </div>

        <div
          style={{
            display: "flex",
            padding: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "640px",
          }}
        >
          <SlotBooking
            api="SLOTS_API"
            caseId={caseId}
            handleBooking={handleBooking}
            selectedInfo={setSelectedInfo}
            title="Please pick a date and time to reschedule your booking"
          />
        </div>
      </div>
    </>
  );
};
