import { baseURL, SECURITY_TOKEN } from "../../helpers/api-config";

export const engagementFeedback = async (data, id) => {
  let res = {};
  try {
    res = await fetch(`${baseURL.API_URL_BASE}save-engagement-feedback/${id}`, {
      method: "POST",
      json: true,
      body: JSON.stringify({ ...data }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SECURITY_TOKEN}`,
      },
    });
  } catch (err) {
    console.warn(err.message);
  } finally {
    return res;
  }
};

export const getUserInfo = async (id) => {
  try {
    // const res = await fetch(
    //   `${baseURL.API_URL_BASE}user-info?phoneNumber=${id}`,
    //   {
    //     method: "GET",
    //     json: true,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${SECURITY_TOKEN}`,
    //     },
    //   }
    // );
    const res = await fetch(
      `${baseURL.PUBLIC_API_URL_BASE}user-info?engagementId=${id}`,
      {
        method: "GET",
        json: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${SECURITY_TOKEN}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error.toString());
  }
};
