import React from "react";
const ArrowCircleRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: 25, height: 25 }}
    fill="white"
    viewBox="0 0 25 25"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#9BBA70"
      strokeWidth={2}
      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export default ArrowCircleRight;
