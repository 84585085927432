import { baseURL } from "../helpers/api-config";


// function to generate url
const getUrl = url => baseURL.API_URL_BASE + url;
// ends

export const UPLOAD_API = caseId => getUrl(`case/${caseId}/upload`);

//Consult call api
export const CONSULT_CALL_API = getUrl("consult_slots");
export const GET_CONSULT_CALL_API = case_id =>
	getUrl(`consult_slots/${case_id}`);

export const SLOTS_API = getUrl("slots");
export const DOCTOR_FORM_SLOT = getUrl("doctor_form_slot");
export const CONFIRM_DOCTOR_FORM_SLOT_API = getUrl(`confirm_doctor_form_slot`);
export const BOOK_APPOINTMENT_API = getUrl("book-appointment");
export const GET_SLOTS_API = case_id => getUrl(`slots/${case_id}`);
export const GET_DOCTOR_SLOTS_API = case_id =>
	getUrl(`slots/doctor/${case_id}`);

export const PHOTO_TRANSACTION_API = transactionId => {
	return getUrl(`photoTransaction/${transactionId}`);
};

//mini Form
export const MF_ONBOARDING_API = getUrl("v2/on-boarding");

export const MF_TRANSACTION_API = (transactionId, value) => {
	if (value) {
		return getUrl(`v2/transaction/${transactionId}?source=${value}`);
	}
	return getUrl(`v2/transaction/${transactionId}`);
};

export const MF_SUBMIT = transactionId => {
	return getUrl(`v2/on-boarding/${transactionId}`);
};

//Calendly Form
export const CF_ONBOARDING_API = value => {
	return getUrl(`v2/onboard-user?source=${value}`);
};

export const V4_ONBOARDING_API = value => {
	return getUrl(`v4/onboard-user?source=${value}`);
};

export const GET_CHOLESTROL_SLOTS_API = case_id => {
	return getUrl(`cholestrol-form-slots/${case_id}`);
};
export const GET_RAZORPAY_PAYMENT_INFO_API = caseId => {
	return getUrl(`razorPay-payment-info/${caseId}`);
};

export const CREATE_RESPONSE_GUEST_FORM = getUrl(`v5/create-response`);

export const UPDATE_RESPONSE_GUEST_FORM = id => {
	return getUrl(`v5/update-response/${id}`);
};

export const LOCK_CONSULT_DOCTOR_API = slotId => {
	return getUrl(`lock-consult-doctor-slot/${slotId}`);
};

export const NEW_FEEDBACK_FORM_DEV = "https://formv2s.dev.hav-g.in/nextcart"
export const NEW_FEEDBACK_FORM = "https://form.traya.health/nextcart"

