// API_URL_BASE: "http://localhost:5000/",
export const baseURL = {
	API_URL_BASE: "https://api.hav-g.in/",
	PUBLIC_API_URL_BASE: "https://public-jgfas325.hav-g.in/",
	PREVIEW_URL: {
		FEMALE: "https://traya.health/pages/result-f/",
		MALE: "https://traya.health/pages/result4/",
	},
	FORM_URL: "https://form.traya.health/",
};
export const SECURITY_TOKEN = "d7ef603e-71ea-44a1-93f2-2bacd08c4a90";

export const USER_PING_CALL = 60000;
export const PUBLIC_VAPID_KEY ="BCho54cnMquwR-3qp3lJTZUKf9jFikDTWCa7XXYYAIelmLdsCXRpTGBE2NmH5SadfdwOOzBSSD0yeg2NnoU66JA";