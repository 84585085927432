import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Layout, Space, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import trayaLogo from "../../../assets/traya.png";
import { getUserInfo } from "../../engagementfeedback/service";

const ThankYouPageV2 = () => {
  let history = useHistory();
  let { id } = useParams();
  const [data, setData] = useState({});
  const [cartLink, setCartLink] = useState("");
  const [newCartLink, setNewCartLink] = useState("");
  const [recommendation, setRecommendation] = useState({});
  const [nextCartRecommendation, setNextCartRecommendation] = useState({});
  const [userAnalysis, setUserAnalysis] = useState({});

  const userDetails = async (id) => {
    const _res = await getUserInfo(id);
    setRecommendation(_res.Recommendations);
    setData(_res.order);
    setUserAnalysis(_res.User_Analysis);
  };
  useEffect(() => {
    userDetails(id);
    let nextCartLink = window.localStorage.getItem("next_cart_link");
    setCartLink(nextCartLink);
    let nextNewCartLink = window.localStorage.getItem("next_cart_link_3_months")
    setNewCartLink(nextNewCartLink)
    let tempppp = window.localStorage.getItem("recommendations");
    setNextCartRecommendation(JSON.parse(tempppp));
    return () => {
      window.localStorage.removeItem("hair_loss");
      window.localStorage.removeItem("concerns");
      window.localStorage.removeItem("dandruff_feedback");
    };
  }, [id]);


  let gutHealth = window.localStorage.getItem("gut_health");
  let gutHealthText = "";
  let gutHealthWidth;
  let metabolism = window.localStorage.getItem("metabolism");
  let metabolismText = "";
  let metabolismWidth;
  let mood = window.localStorage.getItem("mood");
  let moodText = "";
  let moodWidth;
  let dandruff = window.localStorage.getItem("dandruff");
  let dandruffText = "";
  let dandruffWidth;
  let hairFall = window.localStorage.getItem("hair_fall");
  let hairFallText = "";
  let hairFallWidth;

  switch (gutHealth) {
    case "1":
      gutHealthText = "Worse";
      gutHealthWidth = 30;
      break;
    case "5":
      gutHealthText = "No change";
      gutHealthWidth = 55;
      break;
    case "10":
      gutHealthText = "Better";
      gutHealthWidth = 100;
      break;
    default:
      break;
  }
  switch (metabolism) {
    case "1":
      metabolismText = "Worse";
      metabolismWidth = 30;
      break;
    case "5":
      metabolismText = "No change";
      metabolismWidth = 55;
      break;
    case "10":
      metabolismText = "Better";
      metabolismWidth = 100;
      break;
    default:
      break;
  }
  switch (mood) {
    case "1":
      moodText = "Worse";
      moodWidth = 30;
      break;
    case "5":
      moodText = "No change";
      moodWidth = 55;
      break;
    case "10":
      moodText = "Better";
      moodWidth = 100;
      break;
    default:
      break;
  }
  switch (dandruff) {
    case "1":
      dandruffText = "Worse";
      dandruffWidth = 30;
      break;
    case "5":
      dandruffText = "No change";
      dandruffWidth = 55;
      break;
    case "10":
      dandruffText = "Better";
      dandruffWidth = 100;
      break;
    default:
      break;
  }
  switch (hairFall) {
    case "1":
      hairFallText = "Worse";
      hairFallWidth = 30;
      break;
    case "5":
      hairFallText = "No change";
      hairFallWidth = 55;
      break;
    case "10":
      hairFallText = "Better";
      hairFallWidth = 100;
      break;
    default:
      break;
  }

  const goBack = () => {
    history.push(`/${id}`);
  };

  const keys = Object.keys(userAnalysis);
  let rootCause = "";
  let rootCauseArr = [];
  keys.map((key) => {
    switch (key) {
      case "sleep":
        rootCause = "Sleep";
        break;
      case "stress":
        rootCause = "Stress";
        break;
      case "gut_health":
        rootCause = "Gut Health";
        break;
      case "energy":
        rootCause = "Energy";
        break;
      default:
        return;
    }
    rootCauseArr.push(rootCause);
  });

  return (
    <Layout style={{ backgroundColor: "White" }}>
      <div>
        <Row
          align="middle"
          justify="center"
          style={{ width: "100%", position: "fixed", overflowY: "hidden" }}
        >
          <div style={{ width: "100%", backgroundColor: "rgb(65,64,66)" }}>
            <img
              src={trayaLogo}
              alt="Logo"
              style={{
                marginTop: "10px",
                marginLeft: "17px",
                padding: "17px",
                cursor: "pointer",
              }}
              onClick={goBack}
              width={130}
            />
          </div>
        </Row>
        {/* <Row align="middle" justify="center">
          <div
            className="cardView container-3"
            style={{ border: "1px solid #B7D340", borderRadius: "9px" }}
          >
            <div className="userCard">
              <div className="userContainer">
                <div
                  style={{
                    width: "250px",
                    height: "29px",
                    marginTop: "-8px",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#414042",
                    }}
                  >
                    {userAnalysis.first_name}
                  </span>
                  {userAnalysis.age && <span
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#414042",
                    }}
                  >
                    {", "}
                    {userAnalysis.age}
                  </span>}
                  {userAnalysis.gender && <span
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "22px",
                      color: "#414042",
                    }}
                  >
                    {", "}
                    {userAnalysis.gender === "M" ? "Male" : "Female"}
                  </span>}
                </div>

                <div
                  style={{
                    width: "90px",
                    height: "55px",
                    marginTop: "10px",
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      width: "90px",
                      height: "25px",
                      marginTop: "-18px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "19px",
                        color: "#000000",
                      }}
                    >
                      {moment(data.delivery_date).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "90px",
                      height: "25px",
                      marginTop: "-6px",
                      marginLeft: "7px",
                    }}
                  >
                    {data.order_display_id && <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "9px",
                        lineHeight: "12px",
                        color: "#414042",
                      }}
                    >
                      Order ID: {data.order_display_id}
                    </span>}
                  </div>
                </div>
              </div>

              <div className="rootCauseContainer">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "400",
                      width: "110px",
                      height: "30px",
                      color: "#414042",
                      opacity: "0.7",
                      marginLeft: "-6px",
                      marginTop: "-5px",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: "17px",
                      }}
                    >
                      Current Diagnosis:
                    </span>
                  </div>
                  <div
                    className='diagnosisBarFinal-1'
                  ></div>
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      width: "300px",
                      height: "21px",
                      color: "#000000",
                      marginTop: "-4px",
                      marginLeft: "20px",
                      textAlign: "left",
                    }}
                  >
                    {userAnalysis.gender === "M" ? (
                      <>
                      {recommendation.Type_of_hair_loss && <span
                        style={{
                          fontWeight: "600",
                          fontStyle: "normal",
                          fontSize: "13px",
                          lineHeight: "19px",
                        }}
                      >
                        {" "}
                        {recommendation.Type_of_hair_loss}
                      </span>}
                      {userAnalysis.stage && <span
                        style={{
                          fontWeight: "600",
                          fontStyle: "normal",
                          fontSize: "13px",
                          lineHeight: "19px",
                        }}
                      >
                        {", "}
                        {userAnalysis.stage}
                      </span>}
                      </>
                    ) : (
                      <>
                      <span
                        style={{
                          fontWeight: "600",
                          fontStyle: "normal",
                          fontSize: "13px",
                          lineHeight: "19px",
                        }}
                      >
                        Female pattern hair loss
                      </span>
                      {userAnalysis.stages && <span
                        style={{
                          fontWeight: "600",
                          fontStyle: "normal",
                          fontSize: "13px",
                          lineHeight: "19px",
                        }}
                      >
                        {", "} {userAnalysis.stages}
                      </span>}
                      </>
                    )}
                  </div>
                </div>
                <div className="userCardbar"></div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontStyle: "normal",
                      fontWeight: "400",
                      width: "110px",
                      height: "30px",
                      color: "#414042",
                      opacity: "0.7",
                      marginLeft: "-8px",
                      marginTop: "6px",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: "17px",
                      }}
                    >
                      Root causes affecting hair loss:
                    </span>
                  </div>
                  <div
                    className="diagnosisBarFinal-2"
                  ></div>
                  <div
                    style={{
                      fontFamily: "Nunito Sans, sans-serif",
                      width: "300px",
                      height: "21px",
                      color: "#000000",
                      marginTop: "16px",
                      marginLeft: "17px",
                      textAlign: "left",
                    }}
                  >
                    {rootCauseArr.map((root, i) => {
                      return (
                        <span
                          key={i}
                          style={{
                            fontWeight: "600",
                            fontStyle: "normal",
                            fontSize: "13px",
                            lineHeight: "19px",
                          }}
                        >
                          {i > 0 && ", "}

                          {root}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row> */}

        {(gutHealth || metabolism || mood || dandruff || hairFall) && (
          <Row align="middle" justify="center">
            <div className="cardView container-2">
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ width: "100%", height: "auto" }}>
                  <div style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        fontFamily: "Nunito Sans",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        color: "#414043",
                        opacity: "0.8",
                      }}
                    >
                      Thank you for your feedback.
                    </span>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    {gutHealth && (
                      <>
                        <div className="bar"></div>
                        <br />
                        <span className="progressBarText">
                          {" "}
                          Your gut health
                        </span>
                        <div style={{ display: "flex" }}>
                          <div className="progressBarContainer">
                            <div
                              className="progressBar"
                              style={{ width: `${gutHealthWidth}%` }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontFamily: "Fredoka One",
                              fontSize: "11px",
                              marginTop: "3px",
                              marginLeft: "5px",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {gutHealthText}
                          </div>
                        </div>
                      </>
                    )}

                    {metabolism && (
                      <>
                        <div className="bar"></div>
                        <br />
                        <span className="progressBarText">
                          {" "}
                          Your metabolism{" "}
                        </span>
                        <div style={{ display: "flex" }}>
                          <div className="progressBarContainer">
                            <div
                              className="progressBar"
                              style={{ width: `${metabolismWidth}%` }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontFamily: "Fredoka One",
                              fontSize: "11px",
                              marginTop: "3px",
                              marginLeft: "5px",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {metabolismText}
                          </div>
                        </div>
                      </>
                    )}

                    {mood && (
                      <>
                        <div className="bar"></div>
                        <br />
                        <span className="progressBarText">
                          Your stress & sleep
                        </span>
                        <div style={{ display: "flex" }}>
                          <div className="progressBarContainer">
                            <div
                              className="progressBar"
                              style={{ width: `${moodWidth}%` }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontFamily: "Fredoka One",
                              fontSize: "11px",
                              marginTop: "3px",
                              marginLeft: "5px",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {moodText}
                          </div>
                        </div>
                      </>
                    )}
                    {dandruff && (
                      <>
                        <div className="bar"></div>
                        <br />
                        <span className="progressBarText">
                          {" "}
                          Your dandruff health
                        </span>
                        <div style={{ display: "flex" }}>
                          <div className="progressBarContainer">
                            <div
                              className="progressBar"
                              style={{ width: `${dandruffWidth}%` }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontFamily: "Fredoka One",
                              fontSize: "11px",
                              marginTop: "3px",
                              marginLeft: "5px",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {dandruffText}
                          </div>
                        </div>
                      </>
                    )}

                    {hairFall && (
                      <>
                        <div className="bar"></div>
                        <br />
                        <span className="progressBarText">
                          {" "}
                          Your hair health
                        </span>
                        <div style={{ display: "flex" }}>
                          <div className="progressBarContainer">
                            <div
                              className="progressBar"
                              style={{ width: `${hairFallWidth}%` }}
                            ></div>
                          </div>
                          <div
                            style={{
                              fontFamily: "Fredoka One",
                              fontSize: "11px",
                              marginTop: "3px",
                              marginLeft: "5px",
                              fontWeight: "500",
                              color: "#000000",
                            }}
                          >
                            {hairFallText}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Space>
            </div>
          </Row>
       )} 

        <Row align="middle" justify="center">
          <div
            style={{
              padding: "20px 20px",
            }}
          className={`cardView ${(gutHealth || metabolism || mood || dandruff || hairFall) ? "container-1" : "container-2"}`}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                {nextCartRecommendation.Anti_danruff_shampoo &&
                  !nextCartRecommendation.Kevon &&
                  (nextCartRecommendation.Minoxidil ||
                    nextCartRecommendation.RCP) && (
                    <>
                      <div style={{ marginTop: "10px" }}>
                        <span
                          style={{
                            fontFamily: "Fredoka One",
                            fontStyle: "normal",
                            fontSize: "30px",
                            fontWeight: "400",
                            lineHeight: "52px",
                            textAlign: "center",
                            color: "#B5D13F",
                          }}
                        >
                          GREAT!
                        </span>
                        <br />
                        <span
                          style={{
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "116.9%",
                            color: "#000000",
                          }}
                        >
                          You have achieved month 1 goal - Controlling dandruff
                        </span>
                      </div>
                      <div className="bar"></div>
                    </>
                  )}

                {!nextCartRecommendation.Anti_danruff_shampoo && (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <span
                        style={{
                          fontFamily: "Fredoka One",
                          fontStyle: "normal",
                          fontSize: "30px",
                          fontWeight: "400",
                          lineHeight: "52px",
                          textAlign: "center",
                          color: "#B5D13F",
                        }}
                      >
                        SUPERB!
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontSize: "20px",
                          fontWeight: "700",
                          lineHeight: "116.9%",
                          color: "#000000",
                        }}
                      >
                        We are on a very good track.
                      </span>
                    </div>
                    <div className="bar"></div>
                  </>
                )}

                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {nextCartRecommendation.Anti_danruff_shampoo &&
                    !nextCartRecommendation.Kevon &&
                    (nextCartRecommendation.Minoxidil ||
                      nextCartRecommendation.RCP) && (
                      <div>
                        <span
                          style={{
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18px",
                            color: "#414143",
                            opacity: "0.8",
                          }}
                        >
                          Now, in the next month, we will start a hair growth
                          serum that will start working on thickening and growth
                          of hair. This serum does not give results if the scalp
                          is full of dandruff so keep an eye on dandruff and
                          keep using the anti-dandruff shampoo to keep the
                          dandruff away.
                        </span>
                      </div>
                    )}
                  {nextCartRecommendation.Anti_danruff_shampoo &&
                    nextCartRecommendation.Kevon &&
                    (nextCartRecommendation.Minoxidil ||
                      nextCartRecommendation.RCP) && (
                      <div>
                        <span
                          style={{
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18px",
                            color: "#414143",
                            opacity: "0.8",
                          }}
                        >
                          Please use anti-dandruff shampoo twice a week and
                          lotion daily to control your dandruff. We are adding a
                          hair serum this month which is critical to see the result
                          in hair growth.
                        </span>
                        <br />
                        <br />
                        <ul
                          style={{
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18px",
                            color: "#414143",
                            opacity: "0.8",
                            textAlign: "left",
                          }}
                        >
                          <li>
                            Use kevon every alternate day for 15 days to help
                            with further reduction of your dandruff
                          </li>
                          <li>
                            Once reduced significantly, start using the serum
                            everyday as directed in your prescription
                          </li>
                        </ul>
                      </div>
                    )}
                  {!nextCartRecommendation.Anti_danruff_shampoo &&
                    nextCartRecommendation.Minoxidil && (
                      <div>
                        <span
                          style={{
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18px",
                            color: "#414143",
                            opacity: "0.8",
                          }}
                        >
                          Please note Minoxidil will cause excessive shedding in
                          month 1 & 2.
                          <strong>
                            {" "}
                            Please do not stop or be irregular with Minoxidil.
                          </strong>{" "}
                          It sheds the thin, weak and dead hair out to make
                          space for future hair regrowth. These hair would have
                          fallen out slowly in the next 3 months. Now it is very
                          important that you don't stop Minoxidil in between. Be
                          the most regular with this serum.
                        </span>
                      </div>
                    )}
                  {!nextCartRecommendation.Minoxidil && (
                    <div>
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          color: "#414143",
                          opacity: "0.8",
                        }}
                      >
                        Remember, hair growth takes time and patience so be
                        regular with your hair routine.
                        <br />
                        We have built your next month plan based on the feedback
                        given.
                      </span>
                    </div>
                  )}
                </div>
                <Button className="finalBtn" href={cartLink} type="link">
                  <span style={{ marginTop: "8px" }}>
                    {" "}
                    ORDER MY NEXT MONTH KIT
                  </span>
                </Button>
                <Button className="finalBtn" href={newCartLink} type="link">
                  <span style={{ marginTop: "8px" }}>
                    {" "}
                    ORDER MY NEXT 3 MONTH KIT
                  </span>
                </Button>
              </div>
            </Space>
          </div>
        </Row>
      </div>
    </Layout>
  );
};

export default ThankYouPageV2;
