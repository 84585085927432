import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import Modal from "./Modal";

const SlotsList = ({ selectedDate, slots, selectedSlot, setSlot }) => {
  const [modalOn, setModalOn] = useState(false);
  const [currentSlot, setCurrentSlot] = useState({});

  const _slotsList = useMemo(() => {
    if (!selectedDate) return [];

    const _selectedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    return slots[_selectedDate];
  }, [selectedDate, slots]);

  const handleModal = (slot) => {
    setModalOn(true);
    setCurrentSlot(slot);
    window.localStorage.setItem(
      "date",
      dayjs(slot.slotTime).format("DD-MM-YYYY")
    );
    window.localStorage.setItem(
      "startTime",
      dayjs(slot.slotTime).format("hh:mm A")
    );
  };

  if (!dayjs.isDayjs(selectedDate)) return <></>;

  return (
    <div
      style={{
        width: "max-content",
        height: "100%",
      }}
    >
      <h3
        style={{
          textTransform: "capitalize",
          fontFamily: "Nunito Sans",
          color: "#4d4d4d",
        }}
      >
        select time
      </h3>
      <div
        style={{
          display: "grid",
          height: "230px",
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: "1rem",
          marginTop: "1rem",
          width: "max-content",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gap: "0.5rem",
          fontFamily: "Nunito Sans",
        }}
      >
        {_slotsList.map((slot, index) => {
          let _isSelectedSlot = slot.id === selectedSlot.id;
          let buttonID = _isSelectedSlot ? "Slot_booked_yes" : "";

          return (
            <>
              <button
                type="button"
                disabled={slot?.slots?.count === 0}
                onClick={() => handleModal(slot)}
                key={index}
                id={buttonID}
                className={`bc ${_isSelectedSlot ? "p-color" : "y-color"}`}
                style={{ border: "0.4 solid #D3D3D3" }}
              >
                <span id={`${buttonID}`} className="slotBtnText">
                  {dayjs(slot.slotTime).format("h:mm a")}
                </span>
              </button>
              {modalOn && (
                <Modal
                  setModalOn={setModalOn}
                  setSlot={setSlot}
                  slot={currentSlot}
                />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SlotsList;
