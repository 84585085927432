import React, { useEffect, useState } from "react";
import { Row, Layout, Space, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import trayaLogo from "../../../assets/traya.png";
import { getUserInfo } from "../../engagementfeedback/service";

const ThankYouPage = () => {
  let history = useHistory();
  let { id } = useParams();
  const [isHairLossWorst, setIsHairLossWorst] = useState(0);
  const [isDandruffFeedback, setIsDandruffFeedback] = useState(0);
  const [caseID, setCaseID] = useState("");

  useEffect(() => {
    userDetails(id);
    let isHairLoss = window.localStorage.getItem("hair_loss");
    setIsHairLossWorst(isHairLoss);
    let isDandruff = window.localStorage.getItem("dandruff_feedback");
    setIsDandruffFeedback(isDandruff);
    let caseId = window.localStorage.getItem("case_id");
    setCaseID(caseId);
  }, [id]);

  const userDetails = async (id) => {
    const _res = await getUserInfo(id);
  };

  const goBack = () => {
    history.push(`/${id}`);
  };

  const onSubmit = () => {
    history.push(`/slot-booking/${caseID}`);
  };

  return (
    <Layout style={{ backgroundColor: "White" }}>
      <div>
        <Row
          align="middle"
          justify="center"
          style={{ width: "100%", position: "fixed", overflowY: "hidden" }}
        >
          <div style={{ width: "100%", backgroundColor: "rgb(65,64,66)" }}>
            <img
              src={trayaLogo}
              alt="Logo"
              style={{
                marginTop: "10px",
                marginLeft: "17px",
                padding: "17px",
                cursor: "pointer",
              }}
              onClick={goBack}
              width={130}
            />
          </div>
        </Row>

        <Row align="middle" justify="center">
          <div className="cardView container">
            <Space direction="vertical" style={{ width: "100%" }}>
              <div style={{ width: "100%", height: "auto" }}>
                <div style={{ marginTop: "10px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#414043",
                      opacity: "0.8",
                    }}
                  >
                    Thank you for your feedback.
                  </span>
                </div>
                <div className="bar"></div>
                <div style={{ marginTop: "25px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "116.9%",
                      color: "#414143",
                    }}
                  >
                    Since you have marked below, we need you to{" "}
                    <strong>book an appointment</strong> with your{" "}
                    <strong>coach</strong> who will discuss and plan next steps.
                  </span>
                </div>
                <div className="bar"></div>

                {isHairLossWorst !== null && (
                  <div className="card">
                    <span
                      style={{
                        fontFamily: "Nunito Sans",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#414043",
                      }}
                    >
                      How is your hair fall now?{" "}
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Nunito Sans",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#414043",
                        opacity: "0.8",
                      }}
                    >
                      A{")"} Better B{")"} No Change
                      <strong> C{")"} Worse</strong>
                    </span>
                  </div>
                )}
                {isDandruffFeedback !== null && (
                  <div className="card">
                    <span
                      style={{
                        fontFamily: "Nunito Sans",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#414043",
                      }}
                    >
                      Any change in dandruff ?
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Nunito Sans",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#414043",
                        opacity: "0.8",
                      }}
                    >
                      A{")"} Better B{")"} No Change
                      <strong> C{")"} Worse</strong>
                    </span>
                  </div>
                )}
                <div style={{ marginTop: "20px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#414143",
                      opacity: "0.8",
                    }}
                  >
                    Remember, Traya's amazing team of doctors is here to find a
                    way.
                  </span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <span
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#414143",
                      opacity: "0.8",
                    }}
                  >
                    Sometimes all it takes is a small change in the treatment to
                    get back on track.
                  </span>
                </div>
                <Button
                  className="bookingBtn"
                  target="_blank"
                  type="link"
                  onClick={onSubmit}
                >
                  <span style={{ marginTop: "5px" }}> BOOK A CALL</span>
                </Button>
              </div>
            </Space>
          </div>
        </Row>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
