import React from "react";
import { Route, Switch } from "react-router-dom";
import { RescheduleSlots } from "./pages/rescheduleslots/components/RescheduleSlots";
import NewEngagementFeedback from "./pages/engagementfeedback/components/NewEngagementFeedback";
import ThankYouPage from "./pages/thankyou/components/ThankYouPage";
import ThankYouPageV1 from "./pages/thankyou/components/ThankYouPageV1";
import ThankYouPageV2 from "./pages/thankyou/components/ThankYouPageV2";
import "./assets/styles/App.less";


function App() {

  return (
    <>
      <Switch>
        <Route exact path="/:id">
          <NewEngagementFeedback />
        </Route>
        <Route path="/thank-you/slot-booking/:id">
          <ThankYouPage />
        </Route>
        <Route exact path="/slot-booking/:id">
          <RescheduleSlots />
        </Route>
        <Route exact path="/thank-you-final/:id">
          <ThankYouPageV1 />
        </Route>
        <Route exact path="/thank-you-cart/:id">
          <ThankYouPageV2 />
        </Route>
      </Switch>
    </>
  );
}

export default App;
